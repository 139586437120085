import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import MapSearch from "./MapSearch";
const libraries = ["places"];

const containerStyle = {
  width: "100%",
  height: "300px",
  borderRadius: "5px",
};

function Map({ location, setLocation, error }) {
  const { isLoaded } = useJsApiLoader({
    id: "aldahyan_branches_map",
    googleMapsApiKey: "AIzaSyDMkK4UHqq45dSe02zMBoC92WlnkibzJkI",
    libraries: libraries,
  });

  const [center, setCenter] = useState(
    location ?? {
      lat: 30.210624996825356,
      lng: 31.30594320819256,
    }
  );
  const [marker, setMarker] = useState(location);

  const onMapClick = (e) => {
    setMarker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  useEffect(() => {
    setLocation(marker);
  }, [marker]);

  return isLoaded ? (
    <div className="custom-map uk-width-1-1">
      <MapSearch
        isLoaded={isLoaded}
        setMarker={setMarker}
        setCenter={setCenter}
      />
      {error && (
        <small className="uk-text-danger uk-display-block uk-margin-bottom">
          <span className="uk-margin-small-left" uk-icon="warning"></span>
          {error.message}
        </small>
      )}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        onClick={onMapClick}
      >
        {marker && <Marker position={marker} />}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(Map);
