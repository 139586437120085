import React from "react";
import WarnSignsActions from "./WarnSignsActions";
import WarnSignsGrid from "./WarnSignsGrid";

function WarnSigns() {
  return (
    <div>
      <WarnSignsActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <WarnSignsGrid />
      </div>
    </div>
  );
}

export default WarnSigns;
