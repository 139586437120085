import React, { useEffect } from "react";
import CustomInput from "../../../components/CustomInput/CustomInput";
import StatusSelect from "../../../components/CustomSelect/StatusSelect";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAllFinanceOrders } from "../../../redux/CarsManagement/FinanceOrders/financeOrdersSlice";

function FinanceOrdersPanelSearch() {
  const dispatch = useDispatch();
  const { searchCriteria } = useSelector((state) => state.financeOrders);

  const {
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: searchCriteria,
  });

  useEffect(() => {
    reset(searchCriteria);
  }, [searchCriteria]);

  const searchFinanceOrder = (data) => {
    dispatch(getAllFinanceOrders(data));
  };

  return (
    <form
      className="uk-grid-small uk-child-width-1-3"
      uk-grid=""
      onSubmit={handleSubmit(searchFinanceOrder)}
    >
      <div>
        <label className="uk-form-label uk-text-gray uk-text-bold">
          الجوال
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <CustomInput
            control={control}
            name="mobile"
            error={errors.mobile}
            required={false}
            type="number"
          />
        </div>
      </div>
      <div>
        <label className="uk-form-label uk-text-gray uk-text-bold">
          الحالة
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <StatusSelect
                field={field}
                error={errors.status}
                allowClear
                allowAll
              />
            )}
          />
        </div>
      </div>
      <div>
        <div className="uk-position-relative uk-width-1-1 uk-height-1-1">
          <button
            className="uk-button uk-button-secondary uk-border-rounded uk-position-bottom"
            disabled={!isDirty}
          >
            بحث
          </button>
        </div>
      </div>
    </form>
  );
}

export default FinanceOrdersPanelSearch;
