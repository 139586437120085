import React from "react";
import FinanceOrdersActions from "./FinanceOrdersActions";
import FinanceOrdersGrid from "./FinanceOrdersGrid";
import FinanceOrdersPanelSearch from "./FinanceOrdersPanelSearch";

function FinanceOrders() {
  return (
    <div>
      <FinanceOrdersActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <FinanceOrdersPanelSearch />
        <FinanceOrdersGrid />
      </div>
    </div>
  );
}

export default FinanceOrders;
