import React, { useState } from "react";
import { Autocomplete } from "@react-google-maps/api";

function MapSearch({ isLoaded, setMarker, setCenter }) {
  const [searchResult, setSearchResult] = useState("Result: none");

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setMarker(location);
      setCenter(location);
    }
  }

  if (!isLoaded) {
    return <></>;
  }

  return (
    <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
      <input
        type="text"
        placeholder="ابحث عن مكان"
        className="uk-input uk-border-rounded uk-width-medium uk-margin-small-bottom"
      />
    </Autocomplete>
  );
}

export default MapSearch;
