import React, { useState } from "react";
import bgImage from "../../assets/imgs/login/bg.png";
import logo from "../../assets/imgs/logo/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/User/userSlice";
import LoadingOverlay from "../../helpers/LoadingOverlay/LoadingOverlay";
import { useForm } from "react-hook-form";

function Login() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.user);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onSubmit" });

  const handleLogin = (data) => {
    const credentials = { email: data.email, password: data.password };
    dispatch(userLogin(credentials));
  };

  const toggleShowPassword = (e) => {
    e.preventDefault();

    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <>
      {isLoading ? (
        <LoadingOverlay fullScreen />
      ) : (
        <div
          className="uk-child-width-1-2"
          uk-grid=""
          uk-height-viewport="offset-top: true; offset-bottom: true"
        >
          <div className="uk-flex uk-flex-center uk-flex-middle">
            <form
              className="uk-form-stacked uk-width-1-2"
              onSubmit={handleSubmit(handleLogin)}
            >
              <div className="uk-text-center uk-margin-large-bottom">
                <h3 className="uk-text-secondary">مرحباً بك!</h3>
                <p>قم بتسجيل الدخول إلي حسابك</p>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label uk-text-gray" htmlFor="email">
                  البريد الإلكتروني
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-border-rounded"
                    id="email"
                    type="text"
                    {...register("email", {
                      required: "البريد الإلكتروني مطلوب!",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "تأكد من صيغة البريد الإلكتروني!",
                      },
                    })}
                    aria-invalid={errors.email ? "true" : "false"}
                  />
                </div>
                {errors.email && (
                  <small className="uk-text-danger">
                    <span
                      className="uk-margin-small-left"
                      uk-icon="warning"
                    ></span>
                    {errors.email.message}
                  </small>
                )}
              </div>
              <div className="uk-margin">
                <label
                  className="uk-form-label uk-text-gray"
                  htmlFor="password"
                >
                  كلمة المرور
                </label>
                <div className="uk-form-controls uk-inline uk-width-1-1">
                  <button
                    type="button"
                    className="uk-form-icon uk-form-icon-flip"
                    uk-icon={
                      isPasswordVisible ? "icon: eye-slash" : "icon: eye"
                    }
                    onClick={toggleShowPassword}
                  ></button>
                  <input
                    className="uk-input uk-border-rounded"
                    id="password"
                    type={isPasswordVisible ? "text" : "password"}
                    {...register("password", {
                      required: "كلمة المرور مطلوبة!",
                    })}
                  />
                </div>
                {errors.password && (
                  <small className="uk-text-danger">
                    <span
                      className="uk-margin-small-left"
                      uk-icon="warning"
                    ></span>
                    {errors.password.message}
                  </small>
                )}
              </div>
              <div className="uk-margin-large-top uk-text-center">
                <button className="uk-button uk-border-rounded uk-button-secondary">
                  تسجيل الدخول
                </button>
              </div>
            </form>
          </div>
          <div
            className="uk-flex uk-flex-center uk-flex-middle uk-background-cover"
            data-src={bgImage}
            uk-img=""
          >
            <img src={logo} alt="Aldahyan Logo" />
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
