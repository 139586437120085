import React, { useEffect, useMemo } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  editBrandType,
  getAllBrandTypes,
  patchEditBrandTypeModal,
} from "../../../redux/CarsManagement/BrandTypes/brandTypesSlice";
import StatusSelect from "../../../components/CustomSelect/StatusSelect";
import MaintenanceSelect from "../../../components/CustomSelect/MaintenanceSelect";
import CustomInput from "../../../components/CustomInput/CustomInput";

function BrandTypesGrid({ brandId }) {
  const dispatch = useDispatch();
  const {
    allBrandTypes,
    editBrandTypesModalValues,
    isLoading,
    addOrEditResult,
  } = useSelector((state) => state.brandTypes);

  const {
    formState: { errors, isValid, isDirty },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: editBrandTypesModalValues,
  });

  const resetEditBrandTypeModalValues = () => {
    reset(editBrandTypesModalValues);
  };

  useEffect(() => {
    resetEditBrandTypeModalValues();
  }, [editBrandTypesModalValues]);

  const handleBrandTypeModalValues = (row) => {
    dispatch(patchEditBrandTypeModal(row));
  };

  const handleEditBrandType = (data) => {
    dispatch(editBrandType(data));
  };

  // Get All Brands
  useEffect(() => {
    dispatch(getAllBrandTypes(brandId));
  }, [addOrEditResult, brandId]);

  const columns = useMemo(
    () => [
      {
        Header: "الإسم",
        accessor: "nameAr",
      },
      {
        Header: "الحالة",
        accessor: "status",
        Cell: ({ row }) =>
          row.original.status ? (
            <span className="uk-text-success" uk-icon="check"></span>
          ) : (
            <span className="uk-text-danger" uk-icon="close"></span>
          ),
      },
      {
        Header: "الترتيب",
        accessor: "order",
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <button
              uk-tooltip="تعديل"
              className="uk-icon-button"
              uk-icon="pencil"
              uk-toggle="target: #editBrandTypeModal"
              onClick={() => handleBrandTypeModalValues(row.original)}
            ></button>
            <button
              uk-tooltip="حذف"
              className="uk-icon-button uk-margin-small-right"
              uk-icon="trash"
            ></button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      {/* Edit Brand Type Modal */}
      <div id="ModalOfEditBrandType">
        <div
          id="editBrandTypeModal"
          uk-modal="bg-close: false"
          container="#ModalOfEditBrand"
        >
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
              onClick={resetEditBrandTypeModalValues}
            ></button>
            <div className="uk-modal-header uk-text-center">
              <h2 className="uk-modal-title">تعديل</h2>
            </div>
            <div className="uk-modal-body">
              <form className="uk-grid-small uk-child-width-1-2" uk-grid="">
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الإسم بالعربي
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="nameAr"
                      error={errors.nameAr}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الإسم بالإنجليزي
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="nameEn"
                      error={errors.nameEn}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الترتيب
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="order"
                      error={errors.order}
                      type="number"
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الحالة
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <StatusSelect field={field} error={errors.status} />
                      )}
                      rules={{
                        validate: (v) =>
                          typeof v == "boolean" || "هذا الحقل مطلوب!",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    عرض في الصيانة
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <Controller
                      name="viewinmaintenance"
                      control={control}
                      render={({ field }) => (
                        <MaintenanceSelect
                          field={field}
                          error={errors.viewinmaintenance}
                        />
                      )}
                      rules={{
                        validate: (v) =>
                          typeof v == "boolean" || "هذا الحقل مطلوب!",
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="uk-modal-footer uk-text-left">
              <button
                className={`uk-button uk-button-secondary uk-margin-small-left uk-border-rounded${
                  isValid ? " uk-modal-close" : ""
                }`}
                type="button"
                disabled={!isDirty}
                onClick={handleSubmit(handleEditBrandType)}
              >
                تعديل
              </button>
              <button
                className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
                type="button"
                onClick={resetEditBrandTypeModalValues}
              >
                إلغاء
              </button>
            </div>
          </div>
        </div>
      </div>
      <CustomReactTable
        data={allBrandTypes}
        columns={columns}
        isLoading={isLoading}
      />
    </>
  );
}

export default BrandTypesGrid;
