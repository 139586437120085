import React from "react";
import NewsActions from "./NewsActions";
import NewsGrid from "./NewsGrid";

function News() {
  return (
    <div>
      <NewsActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <NewsGrid />
      </div>
    </div>
  );
}

export default News;
