import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BranchesEffects from "./branchesEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  searchCriteria: {
    nameAr: "",
    status: null,
  },
  allBranches: [],
  addBranchFormValues: {
    nameAr: "",
    nameEn: "",
    addressAr: "",
    addressEn: "",
    phone: "",
    start_work: "",
    end_work: "",
    location: "",
    viewinmaintenance: "",
    order: "",
    status: "",
  },
  editBranchFormValues: null,
  addOrEditResult: {},
  isLoading: false,
};
// Get All Branches
export const getAllBranches = createAsyncThunk(
  "branches/getAllBranches",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await BranchesEffects.getAllBranches(payload ?? {});
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Branch
export const addBranch = createAsyncThunk(
  "branches/addBranch",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await BranchesEffects.addBranch(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Branch
export const editBranch = createAsyncThunk(
  "branches/editBranch",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await BranchesEffects.editBranch(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const branchesSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {
    patchEditBranchFormValues: (state, action) => {
      state.editBranchFormValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get All Branches
    builder.addCase(getAllBranches.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBranches.fulfilled, (state, action) => {
      state.allBranches = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllBranches.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add Branch
    builder.addCase(addBranch.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addBranch.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addBranch.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit Branch
    builder.addCase(editBranch.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editBranch.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editBranch.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditBranchFormValues } = branchesSlice.actions;
export default branchesSlice.reducer;
