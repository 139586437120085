import React from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import LoadingOverlay from "../../helpers/LoadingOverlay/LoadingOverlay";

function CustomReactTable({ data, columns, isLoading }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      autoResetExpanded: true,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const isTableEmpty = data.length < 1;

  return (
    <>
      <table
        className="uk-table uk-table-striped uk-table-middle uk-text-center uk-table-responsive"
        {...getTableProps}
      >
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumn } = column.getHeaderProps();
                  return (
                    <th
                      key={key}
                      {...column.getSortByToggleProps()}
                      {...restColumn}
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span uk-icon="arrow-down"></span>
                        ) : (
                          <span uk-icon="arrow-up"></span>
                        )
                      ) : (
                        ""
                      )}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        {isLoading ? (
          <tbody>
            <tr>
              <td colSpan="20">
                <LoadingOverlay />
              </td>
            </tr>
          </tbody>
        ) : isTableEmpty ? (
          <tbody>
            <tr>
              <td colSpan="20">لا توجد بيانات</td>
            </tr>
          </tbody>
        ) : (
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const { key, ...restRowProps } = row.getRowProps();
              return (
                <tr key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps();
                    return (
                      <td key={key} {...restCellProps}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>

      <nav aria-label="Pagination" className="uk-flex uk-flex-middle">
        <div className="uk-flex uk-flex-middle uk-flex-center">
          <span className="uk-margin-small-left">عرض</span>
          <select
            className="uk-select uk-width-auto"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <ul className="uk-pagination uk-flex-center uk-margin-auto">
          <li>
            <button
              className="uk-icon-button"
              uk-icon="chevron-right"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            ></button>
          </li>
          {pageOptions.map((page, index) => (
            <li key={index}>
              <button
                className={`uk-button uk-button-default uk-padding-small uk-border-remove uk-text-center${
                  pageIndex === page ? " uk-text-primary" : ""
                }`}
                onClick={() => gotoPage(page)}
              >
                {page + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              className="uk-icon-button"
              uk-icon="chevron-left"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            ></button>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default CustomReactTable;
