import React from "react";
import ContactMessagesActions from "./ContactMessagesActions";
import ContactMessagesGrid from "./ContactMessagesGrid";

function ContactMessages() {
  return (
    <div>
      <ContactMessagesActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <ContactMessagesGrid />
      </div>
    </div>
  );
}

export default ContactMessages;
