import axios from "axios";

class FinanceOrdersEffects {
  static controller = "car_finance_orders";

  // Get All Car Finance Orders
  static getAllFinanceOrders = async (payload) => {
    const response = await axios.post(`/${this.controller}/getAll`, payload);
    return response.data;
  };
}

export default FinanceOrdersEffects;
