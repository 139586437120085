import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MaintenanceTypesEffects from "./maintenanceTypesEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  allMaintenanceTypes: [],
  addMaintenanceTypeModalValues: {
    nameAr: "",
    nameEn: "",
    descriptionAr: "",
    descriptionEn: "",
    price: "",
    order: "",
    status: "",
  },
  editMaintenanceTypeModalValues: {
    nameAr: "",
    nameEn: "",
    descriptionAr: "",
    descriptionEn: "",
    price: "",
    order: "",
    status: "",
  },
  addOrEditResult: {},
  isLoading: false,
};

// Get All Maintenance Types
export const getAllMaintenanceTypes = createAsyncThunk(
  "maintenanceTypes/getAllMaintenanceTypes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await MaintenanceTypesEffects.getAllMaintenanceTypes();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Maintenance Type
export const addMaintenanceType = createAsyncThunk(
  "maintenanceTypes/addMaintenanceType",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await MaintenanceTypesEffects.addMaintenanceType(
        payload
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Maintenance Type
export const editMaintenanceType = createAsyncThunk(
  "maintenanceTypes/editMaintenanceType",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await MaintenanceTypesEffects.editMaintenanceType(
        payload
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const maintenanceTypesSlice = createSlice({
  name: "maintenanceTypes",
  initialState,
  reducers: {
    patchEditMaintenanceTypeModal: (state, action) => {
      state.editMaintenanceTypeModalValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get All Maintenance Types
    builder.addCase(getAllMaintenanceTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllMaintenanceTypes.fulfilled, (state, action) => {
      state.allMaintenanceTypes = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllMaintenanceTypes.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add Maintenance Type
    builder.addCase(addMaintenanceType.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addMaintenanceType.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addMaintenanceType.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit Maintenance Type
    builder.addCase(editMaintenanceType.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editMaintenanceType.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editMaintenanceType.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditMaintenanceTypeModal } = maintenanceTypesSlice.actions;
export default maintenanceTypesSlice.reducer;
