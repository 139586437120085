import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addBrandType } from "../../../redux/CarsManagement/BrandTypes/brandTypesSlice";
import StatusSelect from "../../../components/CustomSelect/StatusSelect";
import MaintenanceSelect from "../../../components/CustomSelect/MaintenanceSelect";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { NavLink } from "react-router-dom";

function BrandTypesActions({ brandName, brandId }) {
  const dispatch = useDispatch();
  const { addBrandTypeModalValues } = useSelector((state) => state.brandTypes);

  const {
    formState: { errors, isValid },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: addBrandTypeModalValues,
  });

  const resetAddBrandTypeModalValues = () => {
    reset(addBrandTypeModalValues);
  };

  const handleAddBrandType = (data) => {
    const payload = {
      brand_id: brandId,
      ...data,
    };
    dispatch(addBrandType(payload));
    resetAddBrandTypeModalValues();
  };

  return (
    <>
      <nav className="uk-position-relative" aria-label="Breadcrumb">
        <ul className="uk-breadcrumb">
          <li>
            <NavLink to="/Cars">السيارات</NavLink>
          </li>
          <li>
            <NavLink to="/Brands">المُصنع</NavLink>
          </li>
          <li>
            <span aria-current="page">{brandName}</span>
          </li>
        </ul>
        <button
          className="uk-button uk-button-primary uk-button-small uk-border-rounded uk-position-left"
          uk-toggle="target: #addBrandTypeModal"
        >
          <span className="uk-margin-small-left" uk-icon="plus"></span>
          إنشاء
        </button>
      </nav>

      {/* Add Brand Type Modal */}
      <div id="ModalOfAddBrandType">
        <div
          id="addBrandTypeModal"
          uk-modal="bg-close: false"
          container="#ModalOfBrand"
        >
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
              onClick={resetAddBrandTypeModalValues}
            ></button>
            <div className="uk-modal-header uk-text-center">
              <h2 className="uk-modal-title">إضافة نوع</h2>
            </div>
            <div className="uk-modal-body">
              <form className="uk-grid-small uk-child-width-1-2" uk-grid="">
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الإسم بالعربي
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="nameAr"
                      error={errors.nameAr}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الإسم بالإنجليزي
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="nameEn"
                      error={errors.nameEn}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الترتيب
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="order"
                      error={errors.order}
                      type="number"
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الحالة
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <StatusSelect field={field} error={errors.status} />
                      )}
                      rules={{
                        validate: (v) =>
                          typeof v == "boolean" || "هذا الحقل مطلوب!",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    عرض في الصيانة
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <Controller
                      name="viewinmaintenance"
                      control={control}
                      render={({ field }) => (
                        <MaintenanceSelect
                          field={field}
                          error={errors.viewinmaintenance}
                        />
                      )}
                      rules={{
                        validate: (v) =>
                          typeof v == "boolean" || "هذا الحقل مطلوب!",
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="uk-modal-footer uk-text-left">
              <button
                className={`uk-button uk-button-secondary uk-margin-small-left uk-border-rounded${
                  isValid ? " uk-modal-close" : ""
                }`}
                type="button"
                onClick={handleSubmit(handleAddBrandType)}
              >
                إضافه
              </button>
              <button
                className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
                type="button"
                onClick={resetAddBrandTypeModalValues}
              >
                إلغاء
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BrandTypesActions;
