import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BrandTypesEffects from "./brandTypesEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  searchCriteria: {
    nameAr: "",
    brand_id: "",
    status: null,
  },
  allBrandTypes: [],
  addBrandTypesModalValues: {
    nameAr: "",
    nameEn: "",
    brand_id: "",
    order: "",
    status: "",
    viewinmaintenance: "",
  },
  editBrandTypesModalValues: {
    nameAr: "",
    nameEn: "",
    brand_id: "",
    order: "",
    status: "",
    viewinmaintenance: "",
  },
  addOrEditResult: {},
  isLoading: false,
};

// Search Brand Types
export const searchBrandTypes = createAsyncThunk(
  "brandTypes/searchBrandTypes",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await BrandTypesEffects.searchBrandTypes(payload ?? {});
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Get All Brand Types
export const getAllBrandTypes = createAsyncThunk(
  "brandTypes/getAllBrandTypes",
  async (brandId, { rejectWithValue }) => {
    try {
      const response = await BrandTypesEffects.getAllBrandTypes(brandId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Brand Type
export const addBrandType = createAsyncThunk(
  "brandTypes/addBrandType",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await BrandTypesEffects.addBrandType(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Brand Type
export const editBrandType = createAsyncThunk(
  "brandTypes/editBrandType",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await BrandTypesEffects.editBrandType(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const brandTypesSlice = createSlice({
  name: "brandTypes",
  initialState,
  reducers: {
    patchEditBrandTypeModal: (state, action) => {
      state.editBrandTypesModalValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Search Brand Types
    builder.addCase(searchBrandTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchBrandTypes.fulfilled, (state, action) => {
      state.allBrandTypes = action.payload;
      state.isLoading = false;
    });
    builder.addCase(searchBrandTypes.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Get All Brand Types
    builder.addCase(getAllBrandTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBrandTypes.fulfilled, (state, action) => {
      state.allBrandTypes = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllBrandTypes.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add Brand Type
    builder.addCase(addBrandType.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addBrandType.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addBrandType.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit Brand Type
    builder.addCase(editBrandType.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editBrandType.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editBrandType.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditBrandTypeModal } = brandTypesSlice.actions;
export default brandTypesSlice.reducer;
