import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import Login from "../pages/Auth/Login";
import { PrivateAuth, PrivateRoute } from "../services/PrivateRoutes";
import Brands from "../pages/CarsManagement/Brands/Brands";
import BrandTypes from "../pages/CarsManagement/BrandTypes/BrandTypes";
import Cars from "../pages/CarsManagement/Cars/Cars";
import AddCar from "../pages/CarsManagement/Cars/AddCar";
import EditCar from "../pages/CarsManagement/Cars/EditCar";
import Categories from "../pages/CarsManagement/Categories/Categories";
import AddCategory from "../pages/CarsManagement/Categories/AddCategory";
import EditCategory from "../pages/CarsManagement/Categories/EditCategory";
import CarImages from "../pages/CarsManagement/CarImages/CarImages";
import Offers from "../pages/CarsManagement/Offers/Offers";
import EditOffer from "../pages/CarsManagement/Offers/EditOffer";
import AddOffer from "../pages/CarsManagement/Offers/AddOffer";
import Advices from "../pages/SystemManagement/Advices/Advices";
import Branches from "../pages/SystemManagement/Branches/Branches";
import AddBranch from "../pages/SystemManagement/Branches/AddBranch";
import EditBranch from "../pages/SystemManagement/Branches/EditBranch";
import MaintenanceTypes from "../pages/Maintenance/MaintenanceTypes/MaintenanceTypes";
import MaintenanceOffers from "../pages/Maintenance/MaintenanceOffers/MaintenanceOffers";
import AddMaintenanceOffer from "../pages/Maintenance/MaintenanceOffers/AddMaintenanceOffer";
import EditMaintenanceOffer from "../pages/Maintenance/MaintenanceOffers/EditMaintenanceOffer";
import WarnSigns from "../pages/SystemManagement/WarnSigns/WarnSigns";
import AddWarnSign from "../pages/SystemManagement/WarnSigns/AddWarnSign";
import EditWarnSign from "../pages/SystemManagement/WarnSigns/EditWarnSign";
import CustomerOrders from "../pages/Maintenance/CustomerOrders/CustomerOrders";
import SathaOrders from "../pages/Maintenance/SathaOrders/SathaOrders";
import News from "../pages/SystemManagement/News/News";
import AddNews from "../pages/SystemManagement/News/AddNews";
import EditNews from "../pages/SystemManagement/News/EditNews";
import ContactMessages from "../pages/SystemManagement/ContactMessages/ContactMessages";
import Banks from "../pages/Banks/Banks";
import FinanceOrders from "../pages/CarsManagement/FinanceOrders/FinanceOrders";
import useUserPermissions from "../hooks/useUserPermissions";
import Roles from "../pages/UsersManagement/Roles/Roles";
import AddRole from "../pages/UsersManagement/Roles/AddRole";
import EditRole from "../pages/UsersManagement/Roles/EditRole";
import Users from "../pages/UsersManagement/Users/Users";

function AppRoutes() {
  const { isUserAllowed, isUserAuthenticated } = useUserPermissions();

  return (
    <main
      className="main-content"
      id="main-content"
      uk-height-viewport="offset-top: true; offset-bottom: true"
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/Login"
          element={
            <PrivateAuth isUserAuthenticated={isUserAuthenticated}>
              <Login />
            </PrivateAuth>
          }
        />

        {/* Cars Access */}
        <Route element={<PrivateRoute isUserAllowed={isUserAllowed("Cars")} />}>
          <Route path="/Cars" element={<Cars />} />
          <Route path="/Cars/AddCar" element={<AddCar />} />
          <Route path="/Cars/EditCar" element={<EditCar />} />
          <Route path="/Cars/Categories" element={<Categories />} />
          <Route path="/Cars/AddCategory" element={<AddCategory />} />
          <Route path="/Cars/EditCategory" element={<EditCategory />} />
          <Route path="/Cars/CarImages" element={<CarImages />} />
          <Route path="/Brands" element={<Brands />} />
          <Route path="/Brands/BrandTypes" element={<BrandTypes />} />
          <Route path="/Offers" element={<Offers />} />
          <Route path="/Offers/AddOffer" element={<AddOffer />} />
          <Route path="/Offers/EditOffer" element={<EditOffer />} />
          <Route path="/FinanceOrders" element={<FinanceOrders />} />
        </Route>

        {/* System Management Access */}
        <Route
          element={
            <PrivateRoute isUserAllowed={isUserAllowed("SystemManagement")} />
          }
        >
          <Route path="/SystemManagement/Advices" element={<Advices />} />
          <Route path="/Branches" element={<Branches />} />
          <Route path="/Branches/AddBranch" element={<AddBranch />} />
          <Route path="/Branches/EditBranch" element={<EditBranch />} />
          <Route path="/WarnSigns" element={<WarnSigns />} />
          <Route path="/WarnSigns/AddWarnSign" element={<AddWarnSign />} />
          <Route path="/WarnSigns/EditWarnSign" element={<EditWarnSign />} />
          <Route path="/News" element={<News />} />
          <Route path="/News/AddNews" element={<AddNews />} />
          <Route path="/News/EditNews" element={<EditNews />} />
          <Route path="/ContactMessages" element={<ContactMessages />} />
        </Route>

        {/* Maintenance Access */}
        <Route
          element={
            <PrivateRoute isUserAllowed={isUserAllowed("Maintenance")} />
          }
        >
          <Route path="/MaintenanceTypes" element={<MaintenanceTypes />} />
          <Route path="/MaintenanceOffers" element={<MaintenanceOffers />} />
          <Route
            path="/MaintenanceOffers/AddMaintenanceOffer"
            element={<AddMaintenanceOffer />}
          />
          <Route
            path="/MaintenanceOffers/EditMaintenanceOffer"
            element={<EditMaintenanceOffer />}
          />
          <Route path="/CustomerMtcOrders" element={<CustomerOrders />} />
          <Route path="/SathaOrders" element={<SathaOrders />} />
        </Route>

        {/* Banks Access */}
        <Route
          path="/Banks"
          element={
            <PrivateRoute isUserAllowed={isUserAllowed("Banks")}>
              <Banks />
            </PrivateRoute>
          }
        />

        {/* User Management Access */}
        <Route
          element={
            <PrivateRoute isUserAllowed={isUserAllowed("UserManagement")} />
          }
        >
          <Route path="/Roles" element={<Roles />} />
          <Route path="/Roles/AddRole" element={<AddRole />} />
          <Route path="/Roles/EditRole" element={<EditRole />} />
          <Route path="/Users" element={<Users />} />
        </Route>
      </Routes>
    </main>
  );
}

export default AppRoutes;
