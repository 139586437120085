import React from "react";
import { NavLink } from "react-router-dom";

function CategoriesActions({ carName, carId }) {
  return (
    <nav className="uk-position-relative" aria-label="Breadcrumb">
      <ul className="uk-breadcrumb">
        <li>
          <NavLink to="/Cars">السيارات</NavLink>
        </li>
        <li>
          <span>الفئات</span>
        </li>
        <li>
          <span aria-current="page">{carName}</span>
        </li>
      </ul>
      <NavLink
        to="/Cars/AddCategory"
        className="uk-button uk-button-primary uk-button-small uk-border-rounded uk-position-left"
        state={{ carId }}
      >
        <span className="uk-margin-small-left" uk-icon="plus"></span>
        إنشاء
      </NavLink>
    </nav>
  );
}

export default CategoriesActions;
