import React, { useEffect, useMemo } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  deleteCar,
  getAllCars,
  patchEditCarFormValues,
} from "../../../redux/CarsManagement/Cars/carsSlice";
import confirmAlert from "../../../helpers/ConfirmAlert";

function CarsGrid() {
  const dispatch = useDispatch();
  const { allCars, isLoading, actionsResult } = useSelector(
    (state) => state.cars
  );

  // Patch Car info
  const handleEditCar = (row) => {
    dispatch(patchEditCarFormValues(row));
  };

  const handleDeleteCar = (id) => {
    confirmAlert({
      title: "حذف السيارة",
      message: "هل أنت متأكد من حذف هذه السيارة؟",
      onConfirm: () => dispatch(deleteCar(id)),
    });
  };

  // Get All Cars
  useEffect(() => {
    dispatch(getAllCars());
  }, [actionsResult]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "اسم السيارة",
        accessor: "nameAr",
      },
      {
        Header: "المُصنع",
        accessor: "brand.brandNameAr",
      },
      {
        Header: "النوع",
        accessor: "brand.nameAr",
      },
      {
        Header: "سنة الصنع",
        accessor: "manufacture_year",
      },
      {
        Header: "الفئات",
        Cell: ({ row }) => (
          <NavLink
            className="uk-button uk-button-link uk-border-rounded uk-text-secondary uk-text-bold"
            to="/Cars/Categories"
            state={{
              carId: row.original.id,
              carName: row.original.nameAr,
            }}
          >
            عرض
          </NavLink>
        ),
      },
      {
        Header: "الصور",
        Cell: ({ row }) => (
          <NavLink
            className="uk-button uk-button-link uk-border-rounded uk-text-secondary uk-text-bold"
            to="/Cars/CarImages"
            state={{
              carId: row.original.id,
              carName: row.original.nameAr,
            }}
          >
            عرض
          </NavLink>
        ),
      },
      {
        Header: "الحالة",
        accessor: "status",
        Cell: ({ row }) =>
          row.original.status ? (
            <span className="uk-text-success" uk-icon="check"></span>
          ) : (
            <span className="uk-text-danger" uk-icon="close"></span>
          ),
      },
      {
        Header: "الترتيب",
        accessor: "order",
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <NavLink
              to="/Cars/EditCar"
              uk-tooltip="تعديل"
              className="uk-icon-button"
              uk-icon="pencil"
              onClick={() => handleEditCar(row.original)}
            ></NavLink>
            <button
              uk-tooltip="حذف"
              className="uk-icon-button uk-margin-small-right"
              uk-icon="trash"
              onClick={() => handleDeleteCar(row.original.id)}
            ></button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <CustomReactTable data={allCars} columns={columns} isLoading={isLoading} />
  );
}

export default CarsGrid;
