import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BanksEffects from "./banksEffects";
import { showNotification } from "../../helpers/ShowNotification";

const initialState = {
  allBanks: [],
  addBankModalValues: {
    nameAr: "",
    nameEn: "",
    finance_rate: "",
    insurance_rate: "",
    ownership_rate: "",
    administrative_fees: "",
    order: "",
    status: "",
  },
  editBankModalValues: {
    nameAr: "",
    nameEn: "",
    finance_rate: "",
    insurance_rate: "",
    ownership_rate: "",
    administrative_fees: "",
    order: "",
    status: "",
  },
  addOrEditResult: {},
  isLoading: false,
};

// Get All Banks
export const getAllBanks = createAsyncThunk(
  "banks/getAllBanks",
  async (_, { rejectWithValue }) => {
    try {
      const response = await BanksEffects.getAllBanks();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Bank
export const addBank = createAsyncThunk(
  "banks/addBank",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await BanksEffects.addBank(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Bank
export const editBank = createAsyncThunk(
  "banks/editBank",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await BanksEffects.editBank(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const banksSlice = createSlice({
  name: "banks",
  initialState,
  reducers: {
    patchEditBankModal: (state, action) => {
      state.editBankModalValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get All Banks
    builder.addCase(getAllBanks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBanks.fulfilled, (state, action) => {
      state.allBanks = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllBanks.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add Bank
    builder.addCase(addBank.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addBank.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addBank.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit Bank
    builder.addCase(editBank.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editBank.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editBank.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditBankModal } = banksSlice.actions;
export default banksSlice.reducer;
