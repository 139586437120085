import React, { useEffect, useMemo } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  deleteCategory,
  getAllCategoriesByCar,
  patchEditCategoryFormValues,
} from "../../../redux/CarsManagement/Categories/categoriesSlice";
import confirmAlert from "../../../helpers/ConfirmAlert";

function CategoriesGrid({ carId }) {
  const dispatch = useDispatch();
  const { allCategoriesByBrand, isLoading, actionsResult } = useSelector(
    (state) => state.categories
  );

  // Get All Categories
  useEffect(() => {
    dispatch(getAllCategoriesByCar(carId));
  }, [actionsResult]);

  // Patch Category info
  const handleEditCategory = (row) => {
    dispatch(patchEditCategoryFormValues(row));
  };

  const handleDeleteCategory = (id) => {
    confirmAlert({
      title: "حذف الفئة",
      message: "هل أنت متأكد من حذف هذه الفئة",
      onConfirm: () => dispatch(deleteCategory(id)),
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "اسم الفئة",
        accessor: "nameAr",
      },
      {
        Header: "السعر",
        accessor: "price",
      },
      {
        Header: "قوة المحرك",
        accessor: "engine_power",
      },
      {
        Header: "ناقل الحركة",
        accessor: "traction_type",
        Cell: ({ row }) =>
          row.original.traction_type === 0 ? "أوتوماتيك" : "مانيوال",
      },
      {
        Header: "الحالة",
        accessor: "status",
        Cell: ({ row }) =>
          row.original.status ? (
            <span className="uk-text-success" uk-icon="check"></span>
          ) : (
            <span className="uk-text-danger" uk-icon="close"></span>
          ),
      },
      {
        Header: "الترتيب",
        accessor: "order",
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <NavLink
              to="/Cars/EditCategory"
              uk-tooltip="تعديل"
              className="uk-icon-button"
              uk-icon="pencil"
              onClick={() => handleEditCategory(row.original)}
            ></NavLink>
            <button
              uk-tooltip="حذف"
              className="uk-icon-button uk-margin-small-right"
              uk-icon="trash"
              onClick={() => handleDeleteCategory(row.original.id)}
            ></button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <CustomReactTable
      data={allCategoriesByBrand}
      columns={columns}
      isLoading={isLoading}
    />
  );
}

export default CategoriesGrid;
