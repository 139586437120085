import React from "react";
import RolesGrid from "./RolesGrid";
import RolesActions from "./RolesActions";

function Roles() {
  return (
    <div>
      <RolesActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <RolesGrid />
      </div>
    </div>
  );
}

export default Roles;
