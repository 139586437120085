import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UsersGrid from "./UsersGrid";
import UsersActions from "./UsersActions";
import { getAllRoles } from "../../../redux/UsersManagement/Roles/rolesSlice";

function Users() {
  const dispatch = useDispatch();
  const { allRoles, isLoading: rolesIsLoading } = useSelector(
    (state) => state.roles
  );

  // Roles Options
  const rolesOptions = allRoles.map((role) => ({
    value: role.roleId,
    label: role.roleName,
  }));

  // Get Roles
  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  return (
    <div>
      {!rolesIsLoading && <UsersActions rolesOptions={rolesOptions} />}
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <UsersGrid
          rolesOptions={rolesOptions}
          rolesIsLoading={rolesIsLoading}
        />
      </div>
    </div>
  );
}

export default Users;
