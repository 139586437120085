import axios from "axios";

class NewsEffects {
  static controller = "news";

  // Get All News
  static getAllNews = async () => {
    const response = await axios.get(`/${this.controller}/getAll`);
    return response.data;
  };

  // Add News
  static addNews = async (payload) => {
    const response = await axios.post(`/${this.controller}/AddOne`, payload);
    return response.data;
  };

  // Edit News
  static editNews = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/${payload.id}`,
      payload.formData
    );
    return response.data;
  };
}

export default NewsEffects;
