import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UsersEffects from "./usersEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  allUsers: [],
  addUserModalValues: {
    name: "",
    roleId: "",
    username: "",
    email: "",
    password: "",
  },
  editUserModalValues: {
    name: "",
    roleId: "",
    username: "",
    email: "",
    password: "",
  },
  addOrEditResult: {},
  isLoading: false,
};

// Get All Users
export const getAllUsers = createAsyncThunk(
  "users/getAllUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await UsersEffects.getAllUsers();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add User
export const addUser = createAsyncThunk(
  "users/addUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await UsersEffects.addUser(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit User
export const editUser = createAsyncThunk(
  "users/editUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await UsersEffects.editUser(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    patchEditUserModal: (state, action) => {
      state.editUserModalValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get All Users
    builder.addCase(getAllUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllUsers.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add User
    builder.addCase(addUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addUser.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit User
    builder.addCase(editUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editUser.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditUserModal } = usersSlice.actions;
export default usersSlice.reducer;
