import axios from "axios";

class BanksEffects {
  static controller = "Banks";

  // Get All Banks
  static getAllBanks = async () => {
    const response = await axios.get(`/${this.controller}/getAll`);
    return response.data;
  };

  // Add Bank
  static addBank = async (payload) => {
    const response = await axios.post(`/${this.controller}/AddOne`, payload);
    return response.data;
  };

  // Edit Bank
  static editBank = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/${payload.id}`,
      payload
    );
    return response.data;
  };
}

export default BanksEffects;
