import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAllCars } from "../../../redux/CarsManagement/Cars/carsSlice";
import { NavLink, useNavigate } from "react-router-dom";
import LoadingOverlay from "../../../helpers/LoadingOverlay/LoadingOverlay";
import StatusSelect from "../../../components/CustomSelect/StatusSelect";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { addOffer } from "../../../redux/CarsManagement/Offers/offersSlice";
import CustomDatePicker from "../../../components/CustomDatePicker";

function AddOffer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addOfferFormValues, isLoading } = useSelector(
    (state) => state.offers
  );

  const { allCars, isLoading: carsIsLoading } = useSelector(
    (state) => state.cars
  );

  // Options For Select
  const carsOptions = allCars.map((car) => ({
    value: car.id,
    label: car.nameAr,
  }));

  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: addOfferFormValues,
  });

  useEffect(() => {
    reset(addOfferFormValues);
  }, [addOfferFormValues]);

  // Get All Cars
  useEffect(() => {
    dispatch(getAllCars());
  }, []);

  const handleAddOffer = (data) => {
    dispatch(addOffer(data)).then((res) => !res.error && navigate("/Offers"));
  };

  return (
    <>
      <nav className="uk-position-relative" aria-label="Breadcrumb">
        <ul className="uk-breadcrumb">
          <li>
            <NavLink to="/Cars">السيارات</NavLink>
          </li>
          <li>
            <NavLink to="/Offers">العروض</NavLink>
          </li>
          <li>
            <span aria-current="page">إضافة عرض</span>
          </li>
        </ul>
      </nav>

      {carsIsLoading ? (
        <LoadingOverlay />
      ) : (
        <div className="uk-padding-small uk-background-default uk-border-rounded">
          <form className="uk-grid-small uk-child-width-1-2" uk-grid="">
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                الإسم بالعربي
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="nameAr"
                  error={errors.nameAr}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                الإسم بالإنجليزي
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="nameEn"
                  error={errors.nameEn}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                السيارة
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <Controller
                  name="car_id"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      placeholder={"اختر سيارة"}
                      field={field}
                      options={carsOptions}
                      error={errors.car_id}
                    />
                  )}
                  rules={{
                    required: "هذا الحقل مطلوب!",
                  }}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                الترتيب
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="order"
                  error={errors.order}
                  type="number"
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                الحالة
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <StatusSelect field={field} error={errors.status} />
                  )}
                  rules={{
                    validate: (v) =>
                      typeof v == "boolean" || "هذا الحقل مطلوب!",
                  }}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                بداية العرض
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomDatePicker
                  control={control}
                  name="offer_start"
                  error={errors.offer_start}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                نهاية العرض
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomDatePicker
                  control={control}
                  name="offer_end"
                  error={errors.offer_end}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                السعر قبل الخصم
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="price_before"
                  error={errors.price_before}
                  type="number"
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                السعر بعد الخصم
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="price_after"
                  error={errors.price_after}
                  type="number"
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                نسبة الخصم
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="offer_discount"
                  error={errors.offer_discount}
                  type="number"
                />
              </div>
            </div>
          </form>
          <div className="uk-margin-top uk-text-left">
            <button
              className="uk-button uk-button-secondary uk-margin-small-left uk-border-rounded"
              type="button"
              disabled={isLoading}
              onClick={handleSubmit(handleAddOffer)}
            >
              {isLoading ? <div uk-spinner="ratio: 0.5"></div> : "إضافه"}
            </button>
            <NavLink
              to="/Offers"
              className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
              type="button"
            >
              إلغاء
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
}

export default AddOffer;
