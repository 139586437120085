import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CarImagesEffects from "./carImagesEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  carImages: [],
  addImageFormValues: {
    file_name: "",
    status: "",
    order: "",
  },
  editImageFormValues: null,
  addOrEditResult: {},
  isLoading: false,
};

// Get Car Images
export const getCarImages = createAsyncThunk(
  "carImages/getCarImages",
  async (carId, { rejectWithValue }) => {
    try {
      const response = await CarImagesEffects.getCarImages(carId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Image
export const addCarImage = createAsyncThunk(
  "carImages/addCarImage",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await CarImagesEffects.addCarImage(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Image
export const editCarImage = createAsyncThunk(
  "carImages/editCarImage",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await CarImagesEffects.editCarImage(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const carImagesSlice = createSlice({
  name: "carImages",
  initialState,
  reducers: {
    patchEditImageFormValues: (state, action) => {
      state.editImageFormValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Car Images
    builder.addCase(getCarImages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCarImages.fulfilled, (state, action) => {
      state.carImages = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getCarImages.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add Image
    builder.addCase(addCarImage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addCarImage.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.addImageFormValues = null;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addCarImage.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit Image
    builder.addCase(editCarImage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editCarImage.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.editImageFormValues = null;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editCarImage.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditImageFormValues } = carImagesSlice.actions;
export default carImagesSlice.reducer;
