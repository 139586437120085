import axios from "axios";

class CustomerOrdersEffects {
  static controller = "customer_maintenance_orders";

  // Get All Customer Maintenance Orders
  static getAllMaintenanceOrders = async (payload) => {
    const response = await axios.post(`/${this.controller}/getAll`, payload);
    return response.data;
  };
}

export default CustomerOrdersEffects;
