import React, { useEffect, useMemo, useState } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDate } from "../../../helpers/DateHelper";
import { getAllMaintenanceOrders } from "../../../redux/Maintenance/CustomerOrders/customerOrdersSlice";

function CustomerOrdersGrid() {
  const dispatch = useDispatch();
  const { allMaintenanceOrders, isLoading } = useSelector(
    (state) => state.customerMtcOrders
  );

  const [modalData, setModalData] = useState();
  const handleOrderModalValues = (data) => {
    setModalData(data);
  };

  // Get All Customer Maintenance Orders & Auto refresh Maintenance Orders list
  useEffect(() => {
    dispatch(getAllMaintenanceOrders());
    setInterval(() => dispatch(getAllMaintenanceOrders()), 120000);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "الإسم",
        accessor: "customer_name",
      },
      {
        Header: "رقم الجوال",
        accessor: "mobile",
      },
      {
        Header: "رقم اللوحة",
        accessor: "car_PlateNumber",
      },
      {
        Header: "الإيميل",
        accessor: "email",
      },
      {
        Header: "ملاحظات",
        accessor: "remarks",
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <button
              uk-tooltip="عرض التفاصيل"
              className="uk-icon-button"
              uk-icon="eye"
              uk-toggle="target: #CustomerOrderDetailsModal"
              onClick={() => handleOrderModalValues(row.original)}
            ></button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <CustomReactTable
        data={allMaintenanceOrders}
        columns={columns}
        isLoading={isLoading}
      />
      {/* Customer Order Details */}
      <div id="CustomerOrderDetailsModal" uk-modal="bg-close: false">
        <div className="uk-modal-dialog">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close=""
          ></button>
          <div className="uk-modal-header uk-text-center">
            <h2 className="uk-modal-title">تفاصيل الطلب</h2>
          </div>
          <div className="uk-modal-body">
            <div className="uk-grid-small uk-child-width-1-2" uk-grid="">
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  اسم العميل
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.customer_name}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  رقم الهاتف
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.mobile}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  الإيميل
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.email}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  المُصنع
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.brandNameAr}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  النوع
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.brandTypeNameAr}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  نوع الصيانة
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.maintenanceName}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  سنة الصنع
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.car_manufactureYear}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  رقم اللوحة
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.car_PlateNumber}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  فرع الصيانة
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.branchName}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  يوم الصيانة
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {getFormattedDate(modalData?.date)}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  وقت الصيانة
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.deliveryTime}
                </div>
              </div>
              <div className="uk-width-1-1">
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  ملاحظات
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.remarks}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerOrdersGrid;
