import React from "react";
import BanksGrid from "./BanksGrid";
import BanksActions from "./BanksActions";

function Banks() {
  return (
    <div>
      <BanksActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <BanksGrid />
      </div>
    </div>
  );
}

export default Banks;
