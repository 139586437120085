import React, { useEffect, useMemo } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  getAllMaintenanceOffers,
  patchEditMaintenanceOfferFormValues,
} from "../../../redux/Maintenance/MaintenanceOffers/maintenanceOffersSlice";
import { getFormattedDate } from "../../../helpers/DateHelper";

function MaintenanceOffersGrid() {
  const dispatch = useDispatch();
  const { allMaintenanceOffers, isLoading, addOrEditResult } = useSelector(
    (state) => state.maintenanceOffers
  );

  // Get All Maintenance Offers
  useEffect(() => {
    dispatch(getAllMaintenanceOffers());
  }, [addOrEditResult]);

  // Patch Maintenance Offer info
  const handleEditMaintenanceOffer = (row) => {
    dispatch(patchEditMaintenanceOfferFormValues(row));
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "عنوان العرض",
        accessor: "nameAr",
      },
      {
        Header: "وصف العرض",
        accessor: "descriptionAr",
      },
      {
        Header: "بداية العرض",
        accessor: "offer_start",
        Cell: ({ row }) => getFormattedDate(row.original.offer_start),
      },
      {
        Header: "نهاية العرض",
        accessor: "offer_end",
        Cell: ({ row }) => getFormattedDate(row.original.offer_end),
      },
      {
        Header: "السعر قبل",
        accessor: "price_before",
      },
      {
        Header: "السعر بعد",
        accessor: "price_after",
      },
      {
        Header: "نسبة الخصم",
        accessor: "offer_discount",
      },
      {
        Header: "الحالة",
        accessor: "status",
        Cell: ({ row }) =>
          row.original.status ? (
            <span className="uk-text-success" uk-icon="check"></span>
          ) : (
            <span className="uk-text-danger" uk-icon="close"></span>
          ),
      },
      {
        Header: "الترتيب",
        accessor: "order",
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <NavLink
              to="/MaintenanceOffers/EditMaintenanceOffer"
              uk-tooltip="تعديل"
              className="uk-icon-button"
              uk-icon="pencil"
              onClick={() => handleEditMaintenanceOffer(row.original)}
            ></NavLink>
            <button
              uk-tooltip="حذف"
              className="uk-icon-button uk-margin-small-right"
              uk-icon="trash"
            ></button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <CustomReactTable
      data={allMaintenanceOffers}
      columns={columns}
      isLoading={isLoading}
    />
  );
}

export default MaintenanceOffersGrid;
