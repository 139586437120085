import axios from "axios";

class RolesEffects {
  static controller = "Auth";

  // Get All Roles
  static getAllRoles = async () => {
    const response = await axios.get(
      `/${this.controller}/getAllRolesWithClaims`
    );
    return response.data;
  };

  // Get Role
  static getRole = async (id) => {
    const response = await axios.get(
      `/${this.controller}/getOneRoleWithClaims?roleId=${id}`
    );
    return response.data;
  };

  // Get Menus List
  static getMenusList = async () => {
    const response = await axios.get(`/Menu/getAll`);
    return response.data;
  };

  // Add Role
  static addRole = async (payload) => {
    const response = await axios.post(
      `/${this.controller}/AddRolesWithClaims`,
      payload
    );
    return response.data;
  };

  // Edit Role
  static editRole = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/UpdateRoleWithClaim?roleId=${payload.roleId}`,
      payload.data
    );
    return response.data;
  };
}

export default RolesEffects;
