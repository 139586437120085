import { useSelector } from "react-redux";

function useUserPermissions() {
  const { user } = useSelector((state) => state.user);
  const isUserAuthenticated = user?.isAuthenticated;
  const permissions = user?.claimsList ?? [];

  const checkIsUserAllowed = (permission) => {
    const isAllowed =
      isUserAuthenticated &&
      permissions.some((item) => item.key === permission);
    return isAllowed;
  };

  return { isUserAllowed: checkIsUserAllowed, isUserAuthenticated };
}

export default useUserPermissions;
