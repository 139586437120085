import SideBar from "./layouts/SideBar";
import NavBar from "./layouts/NavBar";
import AppRoutes from "./routes";
import Login from "./pages/Auth/Login";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCurrentUser } from "./redux/User/userSlice";

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const isAuthenticated = user?.isAuthenticated;

  // Check if user exists
  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  return isAuthenticated ? (
    <>
      <SideBar />
      <NavBar />
      <AppRoutes />
    </>
  ) : (
    <Login />
  );
}

export default App;
