import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import LoadingOverlay from "../../../helpers/LoadingOverlay/LoadingOverlay";
import CustomInput from "../../../components/CustomInput/CustomInput";
import {
  editRole,
  getMenusList,
  getRole,
} from "../../../redux/UsersManagement/Roles/rolesSlice";

function EditRole() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editRoleFormValues, menusList, isLoading, isRoleLoading } =
    useSelector((state) => state.roles);
  const [searchParams] = useSearchParams();
  const roleId = searchParams.get("id");

  // Role Selected Menus
  const roleMenus = editRoleFormValues?.menusList;

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: editRoleFormValues,
  });

  useEffect(() => {
    reset(editRoleFormValues);
  }, [editRoleFormValues]);

  // Get Menus List & Role Details
  useEffect(() => {
    dispatch(getRole(roleId));
    dispatch(getMenusList());
  }, []);

  const handleEditRole = (data) => {
    dispatch(editRole({ data, roleId })).then(
      (res) => !res.error && navigate("/Roles")
    );
  };

  return (
    <>
      <nav className="uk-position-relative" aria-label="Breadcrumb">
        <ul className="uk-breadcrumb">
          <li>
            <NavLink to="/Users">المستخدمين</NavLink>
          </li>
          <li>
            <NavLink to="/Roles">الأدوار</NavLink>
          </li>
          <li>
            <span aria-current="page">تعديل دور</span>
          </li>
        </ul>
      </nav>

      {isLoading || isRoleLoading ? (
        <LoadingOverlay />
      ) : (
        <div className="uk-padding-small uk-background-default uk-border-rounded">
          <form className="uk-grid-small uk-child-width-1-3" uk-grid="">
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                اسم الدور
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="roleName"
                  error={errors.roleName}
                />
              </div>
            </div>

            <h4 className="uk-heading-line uk-margin-medium-top uk-margin-small-bottom uk-width-1-1 uk-text-bold">
              <span>الصلاحيات</span>
            </h4>

            {/* Load All Menus List */}
            {menusList.map((item) => (
              <div key={item.id} className="uk-form-controls">
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  value={item.id}
                  {...register(`menusList`)}
                  defaultChecked={roleMenus?.includes(item.id)}
                />
                {item.name}
              </div>
            ))}
          </form>
          <div className="uk-margin-top uk-text-left">
            <button
              className="uk-button uk-button-secondary uk-margin-small-left uk-border-rounded"
              type="button"
              disabled={!isDirty || isLoading}
              onClick={handleSubmit(handleEditRole)}
            >
              {isLoading ? <div uk-spinner="ratio: 0.5"></div> : "تعديل"}
            </button>
            <NavLink
              to="/Roles"
              className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
              type="button"
            >
              إلغاء
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
}

export default EditRole;
