import axios from "axios";

class UsersEffects {
  static controller = "Auth";

  // Get All Users
  static getAllUsers = async () => {
    const response = await axios.get(`/${this.controller}/getUsers`);
    return response.data;
  };

  // Add User
  static addUser = async (payload) => {
    const response = await axios.post(`/${this.controller}/Register`, payload);
    return response.data;
  };

  // Edit User
  static editUser = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/UpdateUser?Id=${payload.userId}`,
      payload.body
    );
    return response.data;
  };
}

export default UsersEffects;
