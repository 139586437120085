import axios from "axios";

class MaintenanceOffersEffects {
  static controller = "maintenance_offers";

  // Get All Maintenance Offers
  static getAllMaintenanceOffers = async () => {
    const response = await axios.get(`/${this.controller}/getAll`);
    return response.data;
  };

  // Add Maintenance Offer
  static addMaintenanceOffer = async (payload) => {
    const response = await axios.post(`/${this.controller}/AddOne`, payload);
    return response.data;
  };

  // Edit Maintenance Offer
  static editMaintenanceOffer = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/${payload.id}`,
      payload.formData
    );
    return response.data;
  };
}

export default MaintenanceOffersEffects;
