import React from "react";
import { useSelector } from "react-redux";
import AuthService from "../../services/AuthService";

function NavBar() {
  const { user } = useSelector((state) => state.user);
  const auth = AuthService();

  const handleLogout = (e) => {
    e.preventDefault();
    auth.logout();
  };

  const minimizeSidebar = () => {
    document
      .getElementById("main-sidebar")
      .classList.toggle("minimized-sidebar");
  };

  return (
    <>
      <nav className="uk-navbar-container" id="main-navbar">
        <div className="uk-container">
          <div uk-navbar="mode: click">
            <div className="uk-navbar-right">
              <button
                className="uk-button bg-transparent"
                onClick={minimizeSidebar}
              >
                <i className="fi fi-sr-bars-staggered uk-text-secondary"></i>
              </button>
            </div>

            <div className="uk-navbar-left">
              <ul className="uk-navbar-nav">
                <li>
                  <a href="#">
                    <div className="username">
                      <i className="fi fi-sr-circle-user icon-2x"></i>
                      <span className="uk-margin-small-left">{user?.name}</span>
                      <i uk-icon="icon: chevron-down"></i>
                    </div>
                  </a>
                  <div className="uk-navbar-dropdown">
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li>
                        <a href="" onClick={handleLogout}>
                          تسجيل الخروج
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
