import React, { useEffect, useMemo, useState } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../../components/CustomInput/CustomInput";
import {
  editUser,
  getAllUsers,
  patchEditUserModal,
} from "../../../redux/UsersManagement/Users/usersSlice";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";

function UsersGrid({ rolesOptions, rolesIsLoading }) {
  const dispatch = useDispatch();
  const { allUsers, addOrEditResult, editUserModalValues, isLoading } =
    useSelector((state) => state.users);

  // Toggle Show Password
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const toggleShowPassword = (e) => {
    e.preventDefault();

    setIsPasswordVisible(!isPasswordVisible);
  };

  const {
    formState: { errors, isValid, isDirty },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: editUserModalValues,
  });

  const resetEditUserModalValues = () => {
    reset(editUserModalValues);
  };

  useEffect(() => {
    resetEditUserModalValues();
  }, [editUserModalValues]);

  const handleUserModalValues = (row) => {
    const payload = {
      ...row,
      roleId: row.roles.id,
    };
    delete payload.roles;

    dispatch(patchEditUserModal(payload));
  };

  const handleEditUser = (data) => {
    const { userId, ...body } = data;
    dispatch(editUser({ body, userId }));
  };

  // Get All Users
  useEffect(() => {
    dispatch(getAllUsers());
  }, [addOrEditResult]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "userId",
      },
      {
        Header: "الإسم",
        accessor: "name",
      },
      {
        Header: "رمز المستخدم",
        accessor: "username",
      },
      {
        Header: "الإيميل",
        accessor: "email",
      },
      {
        Header: "الدور",
        accessor: "roles.name",
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <button
              uk-tooltip="تعديل"
              className="uk-icon-button"
              uk-icon="pencil"
              uk-toggle="target: #editUserModal"
              onClick={() => handleUserModalValues(row.original)}
            ></button>
            <button
              uk-tooltip="حذف"
              className="uk-icon-button uk-margin-small-right"
              uk-icon="trash"
            ></button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      {/* Edit User Modal */}
      <div id="ModalOfEditUser">
        <div
          id="editUserModal"
          uk-modal="bg-close: false"
          container="#ModalOfEditUser"
        >
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
              onClick={resetEditUserModalValues}
            ></button>
            <div className="uk-modal-header uk-text-center">
              <h2 className="uk-modal-title">تعديل</h2>
            </div>
            <div className="uk-modal-body">
              <form className="uk-grid-small uk-child-width-1-2" uk-grid="">
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الإسم
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="name"
                      error={errors.name}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    رمز المستخدم
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="username"
                      error={errors.username}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الإيميل
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="email"
                      error={errors.email}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    كلمة المرور
                  </label>
                  <div className="uk-form-controls uk-inline uk-width-1-1 uk-margin-small-top">
                    <button
                      type="button"
                      style={{ height: 40 }}
                      className="uk-form-icon uk-form-icon-flip"
                      uk-icon={
                        isPasswordVisible ? "icon: eye-slash" : "icon: eye"
                      }
                      onClick={toggleShowPassword}
                    ></button>
                    <CustomInput
                      control={control}
                      name="password"
                      type={isPasswordVisible ? "text" : "password"}
                      error={errors.password}
                      required={false}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الدور
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <Controller
                      name="roleId"
                      control={control}
                      render={({ field }) => (
                        <CustomSelect
                          placeholder={"اختر دور"}
                          field={field}
                          options={rolesOptions}
                          error={errors.roleId}
                        />
                      )}
                      rules={{
                        required: "هذا الحقل مطلوب!",
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="uk-modal-footer uk-text-left">
              <button
                className={`uk-button uk-button-secondary uk-margin-small-left uk-border-rounded${
                  isValid ? " uk-modal-close" : ""
                }`}
                type="button"
                disabled={!isDirty}
                onClick={handleSubmit(handleEditUser)}
              >
                تعديل
              </button>
              <button
                className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
                type="button"
                onClick={resetEditUserModalValues}
              >
                إلغاء
              </button>
            </div>
          </div>
        </div>
      </div>
      <CustomReactTable
        data={allUsers}
        columns={columns}
        isLoading={isLoading || rolesIsLoading}
      />
      ;
    </>
  );
}

export default UsersGrid;
