import React from "react";
import BranchesGrid from "./BranchesGrid";
import BranchesActions from "./BranchesActions";
import BranchesPanelSearch from "./BranchesPanelSearch";

function Branches() {
  return (
    <div>
      <BranchesActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <BranchesPanelSearch />
        <BranchesGrid />
      </div>
    </div>
  );
}

export default Branches;
