import React, { useEffect, useMemo } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getAllRoles } from "../../../redux/UsersManagement/Roles/rolesSlice";

function RolesGrid() {
  const dispatch = useDispatch();
  const { allRoles, isLoading } = useSelector((state) => state.roles);

  const renderClaimsList = (claims) => {
    const list =
      claims &&
      claims.map((item, index) => (
        <span key={item.key}>
          {item.name}
          {index !== claims.length - 1 && "، "}
        </span>
      ));

    return list;
  };

  // Get All Categories
  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "الدور",
        accessor: "roleName",
      },
      {
        Header: "الصلاحيات",
        accessor: "claimsList",
        Cell: ({ row }) => renderClaimsList(row.original.claimsList),
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <NavLink
              to={`/Roles/EditRole?id=${row.original.roleId}`}
              uk-tooltip="تعديل"
              className="uk-icon-button"
              uk-icon="pencil"
            ></NavLink>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <CustomReactTable data={allRoles} columns={columns} isLoading={isLoading} />
  );
}

export default RolesGrid;
