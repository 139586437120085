import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoute = ({ isUserAllowed, children }) => {
  if (isUserAllowed) {
    return children ? children : <Outlet />;
  }
  return <Navigate to="/" replace />;
};

export const PrivateAuth = ({ isUserAuthenticated, children }) => {
  if (!isUserAuthenticated) {
    return children;
  }
  return <Navigate to="/" replace />;
};
