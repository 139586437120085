import React from "react";
import CarsActions from "./CarsActions";
import CarsPanelSearch from "./CarsPanelSearch";
import CarsGrid from "./CarsGrid";

function Cars() {
  return (
    <div>
      <CarsActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <CarsPanelSearch />
        <CarsGrid />
      </div>
    </div>
  );
}

export default Cars;
