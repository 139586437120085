import axios from "axios";

class ContactMessagesEffects {
  static controller = "customerContacts";

  // Get All Contact Messages
  static getAllContactMessages = async () => {
    const response = await axios.get(`/${this.controller}/getAll`);
    return response.data;
  };
}

export default ContactMessagesEffects;
