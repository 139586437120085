import React from "react";
import { NavLink } from "react-router-dom";

function CustomerOrdersActions() {
  return (
    <nav className="uk-position-relative" aria-label="Breadcrumb">
      <ul className="uk-breadcrumb">
        <li>
          <span>الصيانة</span>
        </li>
        <li>
          <span aria-current="page">طلبات الصيانة</span>
        </li>
      </ul>
    </nav>
  );
}

export default CustomerOrdersActions;
