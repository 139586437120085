import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserEffects from "./userEffects";
import UIkit from "uikit";
import { showNotification } from "../../helpers/ShowNotification";

const initialState = {
  user: {},
  isLoading: true,
};

export const userLogin = createAsyncThunk(
  "user/login",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await UserEffects.userLogin(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  "user/getCurrentUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await UserEffects.getCurrentUser();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const revokeToken = createAsyncThunk(
  "user/revokeToken",
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    try {
      const response = await UserEffects.revokeToken(token);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    patchUserInfo: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Login
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.user = action.payload;
      localStorage.setItem("token", action.payload?.token);
      state.isLoading = false;
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      showNotification({
        message: "من فضلك تأكد من البريد الإلكتروني وكلمة المرور!",
        status: "danger",
      });
      state.isLoading = false;
    });

    // Get Current User
    builder.addCase(getCurrentUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.user = action.payload;
      localStorage.setItem("token", action.payload?.token);
      state.isLoading = false;
    });
    builder.addCase(getCurrentUser.rejected, (state) => {
      state.isLoading = false;
    });

    // Revoke Token
    builder.addCase(revokeToken.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(revokeToken.fulfilled, (state, action) => {
      state.user = {};
      localStorage.removeItem("token");
      state.isLoading = false;
    });
    builder.addCase(revokeToken.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { patchUserInfo } = userSlice.actions;
export default userSlice.reducer;
