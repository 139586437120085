import React from "react";
import AdvicesGrid from "./AdvicesGrid";
import AdvicesActions from "./AdvicesActions";

function Advices() {
  return (
    <div>
      <AdvicesActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <AdvicesGrid />
      </div>
    </div>
  );
}

export default Advices;
