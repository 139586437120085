import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MaintenanceOffersEffects from "./maintenanceOffersEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  allMaintenanceOffers: [],
  addMaintenanceOfferFormValues: {
    nameAr: "",
    nameEn: "",
    descriptionAr: "",
    descriptionEn: "",
    offer_start: "",
    offer_end: "",
    price_before: "",
    price_after: "",
    offer_discount: "",
    ImageFile: "",
    order: "",
    status: "",
  },
  editMaintenanceOfferFormValues: null,
  addOrEditResult: {},
  isLoading: false,
};
// Get All Maintenance Offers
export const getAllMaintenanceOffers = createAsyncThunk(
  "maintenanceOffers/getAllMaintenanceOffer",
  async (_, { rejectWithValue }) => {
    try {
      const response = await MaintenanceOffersEffects.getAllMaintenanceOffers();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Maintenance Offer
export const addMaintenanceOffer = createAsyncThunk(
  "maintenanceOffers/addMaintenanceOffer",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await MaintenanceOffersEffects.addMaintenanceOffer(
        payload
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Maintenance Offer
export const editMaintenanceOffer = createAsyncThunk(
  "maintenanceOffers/editMaintenanceOffer",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await MaintenanceOffersEffects.editMaintenanceOffer(
        payload
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const maintenanceOffersSlice = createSlice({
  name: "maintenanceOffers",
  initialState,
  reducers: {
    patchEditMaintenanceOfferFormValues: (state, action) => {
      state.editMaintenanceOfferFormValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get All Maintenance Offers
    builder.addCase(getAllMaintenanceOffers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllMaintenanceOffers.fulfilled, (state, action) => {
      state.allMaintenanceOffers = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllMaintenanceOffers.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add Maintenance Offer
    builder.addCase(addMaintenanceOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addMaintenanceOffer.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addMaintenanceOffer.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit Maintenance Offer
    builder.addCase(editMaintenanceOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editMaintenanceOffer.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editMaintenanceOffer.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditMaintenanceOfferFormValues } =
  maintenanceOffersSlice.actions;
export default maintenanceOffersSlice.reducer;
