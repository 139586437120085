import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FinanceOrdersEffects from "./financeOrdersEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  searchCriteria: {
    mobile: "",
    status: null,
  },
  allFinanceOrders: [],
  isLoading: false,
};

// Get All Car Finance Orders
export const getAllFinanceOrders = createAsyncThunk(
  "financeOrders/getAllFinanceOrders",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await FinanceOrdersEffects.getAllFinanceOrders(
        payload ?? {}
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const financeOrdersSlice = createSlice({
  name: "financeOrders",
  initialState,
  extraReducers: (builder) => {
    // Get All Car Finance Orders
    builder.addCase(getAllFinanceOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllFinanceOrders.fulfilled, (state, action) => {
      state.allFinanceOrders = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllFinanceOrders.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export default financeOrdersSlice.reducer;
