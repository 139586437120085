import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import StatusSelect from "../../../components/CustomSelect/StatusSelect";
import CustomInput from "../../../components/CustomInput/CustomInput";
import MaintenanceSelect from "../../../components/CustomSelect/MaintenanceSelect";
import { editBranch } from "../../../redux/SystemManagement/Branches/branchesSlice";
import CustomTimePicker from "../../../components/CustomTimePicker";
import Map from "../../../components/Map";

function EditBranch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editBranchFormValues, isLoading } = useSelector(
    (state) => state.branches
  );

  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: editBranchFormValues,
  });

  useEffect(() => {
    reset(editBranchFormValues);
  }, [editBranchFormValues]);

  const handleEditBranch = (data) => {
    dispatch(editBranch(data)).then(
      (res) => !res.error && navigate("/Branches")
    );
  };

  return (
    <>
      <nav className="uk-position-relative" aria-label="Breadcrumb">
        <ul className="uk-breadcrumb">
          <li>
            <span>إدارة نظام التحكم</span>
          </li>
          <li>
            <NavLink to="/Branches">الفروع</NavLink>
          </li>
          <li>
            <span aria-current="page">تعديل فرع</span>
          </li>
        </ul>
      </nav>

      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <form className="uk-grid-small uk-child-width-1-2" uk-grid="">
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الإسم بالعربي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="nameAr"
                error={errors.nameAr}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الإسم بالإنجليزي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="nameEn"
                error={errors.nameEn}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              العنوان بالعربي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="addressAr"
                error={errors.addressAr}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              العنوان بالإنجليزي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="addressEn"
                error={errors.addressEn}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الجوال
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="phone"
                error={errors.phone}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              بداية العمل
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomTimePicker
                control={control}
                name="start_work"
                error={errors.start_work}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              نهاية العمل
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomTimePicker
                control={control}
                name="end_work"
                error={errors.end_work}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الترتيب
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="order"
                error={errors.order}
                type="number"
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الحالة
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <StatusSelect field={field} error={errors.status} />
                )}
                rules={{
                  validate: (v) => typeof v == "boolean" || "هذا الحقل مطلوب!",
                }}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              عرض في الصيانة
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <Controller
                name="viewinmaintenance"
                control={control}
                render={({ field }) => (
                  <MaintenanceSelect
                    field={field}
                    error={errors.viewinmaintenance}
                  />
                )}
                rules={{
                  validate: (v) => typeof v == "boolean" || "هذا الحقل مطلوب!",
                }}
              />
            </div>
          </div>
          <div className="uk-width-1-1">
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الموقع
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <Map
                    setLocation={(location) => field.onChange(location)}
                    location={field.value}
                    error={errors.location}
                  />
                )}
                rules={{ required: "الموقع مطلوب!" }}
              />
            </div>
          </div>
        </form>
        <div className="uk-margin-top uk-text-left">
          <button
            className="uk-button uk-button-secondary uk-margin-small-left uk-border-rounded"
            type="button"
            disabled={isLoading}
            onClick={handleSubmit(handleEditBranch)}
          >
            {isLoading ? <div uk-spinner="ratio: 0.5"></div> : "تعديل"}
          </button>
          <NavLink
            to="/Branches"
            className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
            type="button"
          >
            إلغاء
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default EditBranch;
