import axios from "axios";

class AdvicesEffects {
  static controller = "car_advices";

  // Get All Advices
  static getAllAdvices = async () => {
    const response = await axios.get(`/${this.controller}/getAll`);
    return response.data;
  };

  // Add Advice
  static addAdvice = async (payload) => {
    const response = await axios.post(`/${this.controller}/AddOne`, payload);
    return response.data;
  };

  // Edit Advice
  static editAdvice = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/${payload.id}`,
      payload
    );
    return response.data;
  };
}

export default AdvicesEffects;
