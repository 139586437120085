import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ContactMessagesEffects from "./contactMessagesEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  allContactMessages: [],
  isLoading: false,
};

// Get All Contact Messages
export const getAllContactMessages = createAsyncThunk(
  "contactMessages/getAllContactMessages",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ContactMessagesEffects.getAllContactMessages();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const contactMessagesSlice = createSlice({
  name: "contactMessages",
  initialState,
  extraReducers: (builder) => {
    // Get All Contact Messages
    builder.addCase(getAllContactMessages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllContactMessages.fulfilled, (state, action) => {
      state.allContactMessages = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllContactMessages.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export default contactMessagesSlice.reducer;
