import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import StatusSelect from "../../../components/CustomSelect/StatusSelect";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { addWarnSign } from "../../../redux/SystemManagement/WarnSigns/warnSignsSlice";

function AddWarnSign() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addWarnSignFormValues, isLoading } = useSelector(
    (state) => state.warnSigns
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: addWarnSignFormValues,
  });

  useEffect(() => {
    reset(addWarnSignFormValues);
  }, [addWarnSignFormValues]);

  // Preview selected image
  const [image, setImage] = useState();
  const handleImageChange = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleAddWarnSign = (data) => {
    const formData = new FormData();

    for (let key in data) {
      key === "image"
        ? formData.append(key, data[key][0])
        : formData.append(key, data[key]);
    }

    dispatch(addWarnSign(formData)).then(
      (res) => !res.error && navigate("/WarnSigns")
    );
  };

  return (
    <>
      <nav className="uk-position-relative" aria-label="Breadcrumb">
        <ul className="uk-breadcrumb">
          <li>
            <span>إدارة نظام التحكم</span>
          </li>
          <li>
            <NavLink to="/WarnSigns">العلامات التحذيرية</NavLink>
          </li>
          <li>
            <span aria-current="page">إضافة</span>
          </li>
        </ul>
      </nav>

      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <form className="uk-grid-small uk-child-width-1-2" uk-grid="">
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الإسم بالعربي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="nameAr"
                error={errors.nameAr}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الإسم بالإنجليزي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="nameEn"
                error={errors.nameEn}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الوصف بالعربي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="description_AR"
                error={errors.description_AR}
                textarea
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الوصف بالإنجليزي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="description_EN"
                error={errors.description_EN}
                textarea
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الترتيب
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="order"
                error={errors.order}
                type="number"
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الحالة
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <StatusSelect field={field} error={errors.status} />
                )}
                rules={{
                  validate: (v) => typeof v == "boolean" || "هذا الحقل مطلوب!",
                }}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الصورة
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <div uk-form-custom="">
                <input
                  type="file"
                  aria-label="Custom controls"
                  name="image"
                  {...register("image", {
                    required: "برجاء اختيار صورة!",
                    onChange: handleImageChange,
                    validate: {
                      lessThan2MB: (files) =>
                        files[0]?.size < 2000000 ||
                        "لا يجب أن يتعدى حجم الصورة 2 ميجا بايت",
                      acceptedFormats: (files) =>
                        ["image/jpg", "image/png", "image/jpeg"].includes(
                          files[0]?.type
                        ) || "يجب أن تكون صيغة الصورة JPG, PNG, JPEG",
                    },
                  })}
                  aria-invalid={errors.image ? "true" : "false"}
                />
                <button
                  className="uk-button uk-button-default uk-border-rounded"
                  type="button"
                  tabIndex="-1"
                >
                  إختر صورة
                </button>
                <img
                  src={image}
                  alt="Selected image"
                  className="uk-height-max-small uk-border-rounded uk-margin-small-right uk-align-left"
                />
              </div>
              {errors.image && (
                <small className="uk-text-danger uk-display-block uk-margin-small-top">
                  <span
                    className="uk-margin-small-left"
                    uk-icon="warning"
                  ></span>
                  {errors.image.message}
                </small>
              )}
            </div>
          </div>
        </form>
        <div className="uk-margin-top uk-text-left">
          <button
            className="uk-button uk-button-secondary uk-margin-small-left uk-border-rounded"
            type="button"
            disabled={isLoading}
            onClick={handleSubmit(handleAddWarnSign)}
          >
            {isLoading ? <div uk-spinner="ratio: 0.5"></div> : "إضافه"}
          </button>
          <NavLink
            to="/WarnSigns"
            className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
            type="button"
          >
            إلغاء
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default AddWarnSign;
