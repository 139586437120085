import React, { useEffect, useMemo } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBranches,
  patchEditBranchFormValues,
} from "../../../redux/SystemManagement/Branches/branchesSlice";
import { NavLink } from "react-router-dom";

function BranchesGrid() {
  const dispatch = useDispatch();
  const { allBranches, isLoading } = useSelector((state) => state.branches);

  const handleEditBranch = (row) => {
    dispatch(patchEditBranchFormValues(row));
  };

  // Get All Branches
  useEffect(() => {
    dispatch(getAllBranches());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "اسم الفرع",
        accessor: "nameAr",
      },
      {
        Header: "الجوال",
        accessor: "phone",
      },
      {
        Header: "عنوان الفرع",
        accessor: "addressAr",
      },
      {
        Header: "يعمل من",
        accessor: "start_work",
      },
      {
        Header: "يعمل إلي",
        accessor: "end_work",
      },
      {
        Header: "الحالة",
        accessor: "status",
        Cell: ({ row }) =>
          row.original.status ? (
            <span className="uk-text-success" uk-icon="check"></span>
          ) : (
            <span className="uk-text-danger" uk-icon="close"></span>
          ),
      },
      {
        Header: "عرض في الصيانة",
        accessor: "viewinmaintenance",
        Cell: ({ row }) =>
          row.original.viewinmaintenance ? (
            <span className="uk-text-success" uk-icon="check"></span>
          ) : (
            <span className="uk-text-danger" uk-icon="close"></span>
          ),
      },
      {
        Header: "الترتيب",
        accessor: "order",
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <NavLink
              to="/Branches/EditBranch"
              uk-tooltip="تعديل"
              className="uk-icon-button"
              uk-icon="pencil"
              onClick={() => handleEditBranch(row.original)}
            ></NavLink>
            <button
              uk-tooltip="حذف"
              className="uk-icon-button uk-margin-small-right"
              uk-icon="trash"
            ></button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <CustomReactTable
      data={allBranches}
      columns={columns}
      isLoading={isLoading}
    />
  );
}

export default BranchesGrid;
