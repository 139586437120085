import React from "react";
import mini_logo from "../../assets/imgs/logo/mini_logo.png";
import mini_logo_in_colors from "../../assets/imgs/logo/mini_logo_in_colors.png";
import "./style.css";

function LoadingOverlay({ fullScreen = false, transparent = false }) {
  return (
    <div
      className={`loading-overlay${fullScreen ? " fullScreen" : ""}${
        transparent ? " transparent" : ""
      }`}
    >
      <div className="loading-spinner">
        <div className="l-spinner">
          <img src={fullScreen ? mini_logo : mini_logo_in_colors} />
        </div>
      </div>
    </div>
  );
}

export default LoadingOverlay;
