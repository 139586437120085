import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { patchEditImageFormValues } from "../../../redux/CarsManagement/CarImages/carImagesSlice";
import { base_url } from "../../../helpers/Constants";

function CarImagesGrid() {
  const dispatch = useDispatch();
  const { carImages } = useSelector((state) => state.carImages);

  const handleEditImageFormValues = (data) => {
    dispatch(patchEditImageFormValues(data));
  };

  return (
    <div
      className="uk-grid-small uk-child-width-1-4 uk-margin-medium-top"
      uk-grid=""
      uk-lightbox=""
    >
      {carImages.map((image) => (
        <div className="uk-height-small" key={image.id}>
          <div className="uk-position-relative uk-width-1-1 uk-height-1-1">
            <ul className="uk-list uk-position-small uk-position-top-right">
              <li>
                <button
                  className="uk-icon-button uk-text-primary"
                  uk-icon="pencil"
                  onClick={() => handleEditImageFormValues(image)}
                ></button>
              </li>
              <li>
                <button
                  className="uk-icon-button uk-text-danger"
                  uk-icon="trash"
                ></button>
              </li>
            </ul>
            <a href={`${base_url}/${image.file_name}`}>
              <img
                className="uk-border-rounded uk-width-1-1 uk-height-1-1 uk-object-cover"
                src={`${base_url}/${image.file_name}`}
              />
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CarImagesGrid;
