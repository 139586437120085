import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WarnSignsEffects from "./warnSignsEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  allWarnSigns: [],
  addWarnSignFormValues: {
    nameAr: "",
    nameEn: "",
    description_AR: "",
    description_EN: "",
    image: "",
    order: "",
    status: "",
  },
  editWarnSignFormValues: null,
  addOrEditResult: {},
  isLoading: false,
};
// Get All Warn Signs
export const getAllWarnSigns = createAsyncThunk(
  "warnSigns/getAllWarnSign",
  async (_, { rejectWithValue }) => {
    try {
      const response = await WarnSignsEffects.getAllWarnSigns();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Warn Sign
export const addWarnSign = createAsyncThunk(
  "warnSigns/addWarnSign",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await WarnSignsEffects.addWarnSign(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Warn Sign
export const editWarnSign = createAsyncThunk(
  "warnSigns/editWarnSign",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await WarnSignsEffects.editWarnSign(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const warnSignsSlice = createSlice({
  name: "warnSigns",
  initialState,
  reducers: {
    patchEditWarnSignFormValues: (state, action) => {
      state.editWarnSignFormValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get All Warn Signs
    builder.addCase(getAllWarnSigns.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllWarnSigns.fulfilled, (state, action) => {
      state.allWarnSigns = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllWarnSigns.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add Warn Sign
    builder.addCase(addWarnSign.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addWarnSign.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addWarnSign.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit Warn Sign
    builder.addCase(editWarnSign.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editWarnSign.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editWarnSign.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditWarnSignFormValues } = warnSignsSlice.actions;
export default warnSignsSlice.reducer;
