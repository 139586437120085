import UIkit from "uikit";

export const showNotification = ({ message, status, type }) => {
  UIkit.notification(
    type === "add"
      ? {
          message: "تمت الإضافة بنجاح",
          pos: "top-left",
          status: status ?? "success",
        }
      : type === "delete"
      ? {
          message: "تم الحذف",
          pos: "top-left",
          status: status ?? "danger",
        }
      : type === "edit"
      ? {
          message: "تم التعديل بنجاح",
          pos: "top-left",
          status: status ?? "success",
        }
      : type === "error"
      ? {
          message: "حدثت مشكلة، من فضلك حاول مرة أخرى!",
          pos: "top-left",
          status: status ?? "warning",
        }
      : {
          message: message ?? "",
          pos: "top-left",
          status: status ?? "primary",
        }
  );
};
