import { useDispatch } from "react-redux";
import { patchUserInfo, revokeToken } from "../redux/User/userSlice";

function AuthService() {
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem("token");
    dispatch(revokeToken());
    dispatch(patchUserInfo({}));
  };

  return { logout };
}

export default AuthService;
