import { configureStore } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import rootReducer from "./rootReducer";
import { thunk } from "redux-thunk";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: [],
  // blacklist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: () => [thunk],
});

export const persistor = persistStore(store);
