import React, { useEffect } from "react";
import CustomInput from "../../../components/CustomInput/CustomInput";
import StatusSelect from "../../../components/CustomSelect/StatusSelect";
import { Controller, useForm } from "react-hook-form";
import { getAllCars } from "../../../redux/CarsManagement/Cars/carsSlice";
import { getAllBrandTypes } from "../../../redux/CarsManagement/BrandTypes/brandTypesSlice";
import { getAllBrands } from "../../../redux/CarsManagement/Brands/brandsSlice";
import { manufacturingYears } from "../../../helpers/Utilities";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import LoadingOverlay from "../../../helpers/LoadingOverlay/LoadingOverlay";

function CarsPanelSearch() {
  const dispatch = useDispatch();
  const { searchCriteria } = useSelector((state) => state.cars);

  const { allBrands, isLoading: brandsLoading } = useSelector(
    (state) => state.brands
  );
  const { allBrandTypes, isLoading: brandTypesLoading } = useSelector(
    (state) => state.brandTypes
  );

  // Options For Select
  const manufacturingOptions = manufacturingYears();
  const brandOptions = allBrands.map((brand) => ({
    value: brand.id,
    label: brand.nameAr,
  }));
  const brandTypesOptions = allBrandTypes.map((brandType) => ({
    value: brandType.id,
    label: brandType.nameAr,
  }));

  const {
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: searchCriteria,
  });

  useEffect(() => {
    reset(searchCriteria);
  }, [searchCriteria]);

  // Get All Brands
  useEffect(() => {
    dispatch(getAllBrands());
  }, []);

  // Get Brand Types
  const handleBrandTypes = (brandId) => {
    brandId && dispatch(getAllBrandTypes(brandId));
  };

  const searchCar = (data) => {
    dispatch(getAllCars(data));
  };

  return brandsLoading || brandTypesLoading ? (
    <LoadingOverlay />
  ) : (
    <form
      className="uk-grid-small uk-child-width-1-3"
      uk-grid=""
      onSubmit={handleSubmit(searchCar)}
    >
      <div>
        <label className="uk-form-label uk-text-gray uk-text-bold">
          اسم السيارة
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <CustomInput
            control={control}
            name="nameAr"
            error={errors.nameAr}
            required={false}
          />
        </div>
      </div>
      <div>
        <label className="uk-form-label uk-text-gray uk-text-bold">
          الماركة
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <Controller
            name="brand_id"
            control={control}
            render={({ field }) => (
              <CustomSelect
                placeholder={"اختر مُصنع"}
                field={field}
                options={brandOptions}
                error={errors.brand_id}
                onChange={(val) => handleBrandTypes(val)}
              />
            )}
          />
        </div>
      </div>
      <div>
        <label className="uk-form-label uk-text-gray uk-text-bold">النوع</label>
        <div className="uk-form-controls uk-margin-small-top">
          <Controller
            name="brandType_id"
            control={control}
            render={({ field }) => (
              <CustomSelect
                placeholder={"اختر نوع"}
                field={field}
                options={brandTypesOptions}
                error={errors.brandType_id}
              />
            )}
          />
        </div>
      </div>
      <div>
        <label className="uk-form-label uk-text-gray uk-text-bold">
          سنة الصنع
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <Controller
            name="manufacture_year"
            control={control}
            render={({ field }) => (
              <CustomSelect
                placeholder={"اختر السنة"}
                field={field}
                options={manufacturingOptions}
                error={errors.manufacture_year}
              />
            )}
          />
        </div>
      </div>
      <div>
        <label className="uk-form-label uk-text-gray uk-text-bold">
          الحالة
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <StatusSelect
                field={field}
                error={errors.status}
                allowClear
                allowAll
              />
            )}
          />
        </div>
      </div>
      <div>
        <div className="uk-position-relative uk-width-1-1 uk-height-1-1">
          <button
            className="uk-button uk-button-secondary uk-border-rounded uk-position-bottom"
            disabled={!isDirty}
          >
            بحث
          </button>
        </div>
      </div>
    </form>
  );
}

export default CarsPanelSearch;
