import React from "react";
import SathaOrdersActions from "./SathaOrdersActions";
import SathaOrdersGrid from "./SathaOrdersGrid";
import SathaOrdersPanelSearch from "./SathaOrdersPanelSearch";

function SathaOrders() {
  return (
    <div>
      <SathaOrdersActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <SathaOrdersPanelSearch />
        <SathaOrdersGrid />
      </div>
    </div>
  );
}

export default SathaOrders;
