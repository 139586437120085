import { confirmAlert as alert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./style.css";

function confirmAlert({ title, message, onConfirm, onCancel }) {
  const options = {
    title: title,
    message: message,
    buttons: [
      {
        label: "لا",
        className:
          "uk-button uk-button-primary uk-button-small uk-border-rounded",
        onClick: onCancel,
      },
      {
        label: "نعم",
        onClick: onConfirm,
        className:
          "uk-button uk-button-danger uk-button-small uk-border-rounded",
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
  };

  return alert(options);
}

export default confirmAlert;
