import React from "react";
import { NavLink } from "react-router-dom";

function MaintenanceOffersActions() {
  return (
    <nav className="uk-position-relative" aria-label="Breadcrumb">
      <ul className="uk-breadcrumb">
        <li>
          <span>الصيانة</span>
        </li>
        <li>
          <span aria-current="page">عروض الصيانة</span>
        </li>
      </ul>
      <NavLink
        to="/MaintenanceOffers/AddMaintenanceOffer"
        className="uk-button uk-button-primary uk-button-small uk-border-rounded uk-position-left"
      >
        <span className="uk-margin-small-left" uk-icon="plus"></span>
        إنشاء
      </NavLink>
    </nav>
  );
}

export default MaintenanceOffersActions;
