import React from "react";
import logo from "../../assets/imgs/logo/logo_in_colors.png";

function Home() {
  return (
    <div
      className="uk-container uk-flex uk-flex-center uk-flex-middle"
      style={{ minHeight: "calc(100vh - 160px)" }}
    >
      <div className="uk-position-relative">
        <p className="uk-text-small uk-position-top uk-position-right">
          مرحباً بك في
        </p>
        <img src={logo} alt="Aldahyan Logo" />
      </div>
    </div>
  );
}

export default Home;
