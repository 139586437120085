import axios from "axios";

class MaintenanceTypesEffects {
  static controller = "MaintenanceTypes";

  // Get All Maintenance Types
  static getAllMaintenanceTypes = async () => {
    const response = await axios.get(`/${this.controller}/getAll`);
    return response.data;
  };

  // Add Maintenance Type
  static addMaintenanceType = async (payload) => {
    const response = await axios.post(`/${this.controller}/AddOne`, payload);
    return response.data;
  };

  // Edit Maintenance Type
  static editMaintenanceType = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/${payload.id}`,
      payload
    );
    return response.data;
  };
}

export default MaintenanceTypesEffects;
