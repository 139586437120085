import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BrandTypesActions from "./BrandTypesActions";
import BrandTypesPanelSearch from "./BrandTypesPanelSearch";
import BrandTypesGrid from "./BrandTypesGrid";

function BrandTypes() {
  const { state } = useLocation();
  const brandId = state?.id;
  const brandName = state?.brandName;
  const navigate = useNavigate();

  useEffect(() => {
    !brandId && navigate("/Brands");
  }, [brandId]);

  return (
    <div>
      <BrandTypesActions brandName={brandName} brandId={brandId} />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <BrandTypesPanelSearch />
        <BrandTypesGrid brandId={brandId} />
      </div>
    </div>
  );
}

export default BrandTypes;
