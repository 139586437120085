import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RolesEffects from "./rolesEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  allRoles: [],
  menusList: [],
  addRoleFormValues: {
    roleName: "",
    menusList: [],
  },
  editRoleFormValues: null,
  addOrEditResult: {},
  isLoading: false,
  isRoleLoading: false,
};

// Get All Roles
export const getAllRoles = createAsyncThunk(
  "roles/getAllRoles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await RolesEffects.getAllRoles();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Menus List
export const getMenusList = createAsyncThunk(
  "roles/getMenusList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await RolesEffects.getMenusList();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Role
export const addRole = createAsyncThunk(
  "roles/addRole",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await RolesEffects.addRole(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Role
export const editRole = createAsyncThunk(
  "roles/editRole",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await RolesEffects.editRole(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Role
export const getRole = createAsyncThunk(
  "roles/getRole",
  async (id, { rejectWithValue }) => {
    try {
      const response = await RolesEffects.getRole(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  extraReducers: (builder) => {
    // Get All Roles
    builder.addCase(getAllRoles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      state.allRoles = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllRoles.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Get Menus List
    builder.addCase(getMenusList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMenusList.fulfilled, (state, action) => {
      state.menusList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getMenusList.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add Role
    builder.addCase(addRole.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addRole.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addRole.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit Role
    builder.addCase(editRole.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editRole.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
      // updatePath("/Roles");
    });
    builder.addCase(editRole.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Get Role
    builder.addCase(getRole.pending, (state) => {
      state.isRoleLoading = true;
    });
    builder.addCase(getRole.fulfilled, (state, action) => {
      state.editRoleFormValues = action.payload;
      state.isRoleLoading = false;
    });
    builder.addCase(getRole.rejected, (state) => {
      state.isRoleLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export default rolesSlice.reducer;
