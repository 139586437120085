import axios from "axios";

class CarsEffects {
  static controller = "Cars";

  // Get All Cars
  static getAllCars = async (payload) => {
    const response = await axios.post(`/${this.controller}/getAll`, payload);
    return response.data;
  };

  // Add Car
  static addCar = async (payload) => {
    const response = await axios.post(`/${this.controller}/AddOne`, payload);
    return response.data;
  };

  // Edit Car
  static editCar = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/${payload.id}`,
      payload.formData
    );
    return response.data;
  };

  // Delete Car
  static deleteCar = async (id) => {
    const response = await axios.put(`/${this.controller}/DeleteModel/${id}`);
    return response.data;
  };
}

export default CarsEffects;
