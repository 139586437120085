import axios from "axios";

class CarImagesEffects {
  static controller = "CarImages";

  // Get Car Images
  static getCarImages = async (carId) => {
    const response = await axios.get(
      `/${this.controller}/getAllByCar/${carId}`
    );
    return response.data;
  };

  // Add Image
  static addCarImage = async (payload) => {
    const response = await axios.post(`/${this.controller}/AddOne`, payload);
    return response.data;
  };

  // Edit Image
  static editCarImage = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/${payload.id}`,
      payload.formData
    );
    return response.data;
  };
}

export default CarImagesEffects;
