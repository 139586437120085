import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCarImages } from "../../../redux/CarsManagement/CarImages/carImagesSlice";
import LoadingOverlay from "../../../helpers/LoadingOverlay/LoadingOverlay";
import CarImagesActions from "./CarImagesActions";
import CarImagesGrid from "./CarImagesGrid";
import EditImageForm from "./EditImageForm";
import AddImageForm from "./AddImageForm";

function CarImages() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { editImageFormValues, addOrEditResult, isLoading } = useSelector(
    (state) => state.carImages
  );
  const { carId, carName } = state;

  useEffect(() => {
    dispatch(getCarImages(carId));
  }, [addOrEditResult]);

  useEffect(() => {
    !carId && navigate("/Cars");
  }, []);

  return (
    <div>
      <CarImagesActions carName={carName} />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        {editImageFormValues ? (
          <EditImageForm carId={carId} />
        ) : (
          <AddImageForm carId={carId} />
        )}
        {isLoading ? <LoadingOverlay /> : <CarImagesGrid carId={carId} />}
      </div>
    </div>
  );
}

export default CarImages;
