import axios from "axios";

class OffersEffects {
  static controller = "car_offers";

  // Get All Offers
  static getAllOffers = async () => {
    const response = await axios.get(`/${this.controller}/getAll`);
    return response.data;
  };

  // Add Offer
  static addOffer = async (payload) => {
    const response = await axios.post(`/${this.controller}/AddOne`, payload);
    return response.data;
  };

  // Edit Offer
  static editOffer = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/${payload.id}`,
      payload
    );
    return response.data;
  };
}

export default OffersEffects;
