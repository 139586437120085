import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomerOrdersEffects from "./customerOrdersEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  searchCriteria: {
    mobile: "",
  },
  allMaintenanceOrders: [],
  isLoading: false,
};

// Get All Customer Maintenance Orders
export const getAllMaintenanceOrders = createAsyncThunk(
  "customerMtcOrders/getAllMaintenanceOrders",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await CustomerOrdersEffects.getAllMaintenanceOrders(
        payload ?? {}
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const customerOrdersSlice = createSlice({
  name: "customerMtcOrders",
  initialState,
  extraReducers: (builder) => {
    // Get All Customer Maintenance Orders
    builder.addCase(getAllMaintenanceOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllMaintenanceOrders.fulfilled, (state, action) => {
      state.allMaintenanceOrders = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllMaintenanceOrders.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export default customerOrdersSlice.reducer;
