import React from "react";

function ContactMessagesActions() {
  return (
    <nav className="uk-position-relative" aria-label="Breadcrumb">
      <ul className="uk-breadcrumb">
        <li>
          <span>إدارة نظام التحكم</span>
        </li>
        <li>
          <span aria-current="page">رسائل التواصل</span>
        </li>
      </ul>
    </nav>
  );
}

export default ContactMessagesActions;
