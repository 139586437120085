import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdvicesEffects from "./advicesEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  allAdvices: [],
  addAdviceModalValues: {
    nameAr: "",
    nameEn: "",
    descriptionAr: "",
    descriptionEn: "",
    order: "",
    status: "",
  },
  editAdviceModalValues: {
    nameAr: "",
    nameEn: "",
    descriptionAr: "",
    descriptionEn: "",
    order: "",
    status: "",
  },
  addOrEditResult: {},
  isLoading: false,
};

// Get All Advices
export const getAllAdvices = createAsyncThunk(
  "advices/getAllAdvices",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AdvicesEffects.getAllAdvices();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Advice
export const addAdvice = createAsyncThunk(
  "advices/addAdvice",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AdvicesEffects.addAdvice(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Advice
export const editAdvice = createAsyncThunk(
  "advices/editAdvice",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AdvicesEffects.editAdvice(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const advicesSlice = createSlice({
  name: "advices",
  initialState,
  reducers: {
    patchEditAdviceModal: (state, action) => {
      state.editAdviceModalValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get All Advices
    builder.addCase(getAllAdvices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllAdvices.fulfilled, (state, action) => {
      state.allAdvices = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllAdvices.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add Advice
    builder.addCase(addAdvice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addAdvice.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addAdvice.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit Advice
    builder.addCase(editAdvice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editAdvice.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editAdvice.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditAdviceModal } = advicesSlice.actions;
export default advicesSlice.reducer;
