import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SathaOrdersEffects from "./sathaOrdersEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  searchCriteria: {
    mobile: "",
  },
  allSathaOrders: [],
  isLoading: false,
};

// Get All Customer Satha Orders
export const getAllSathaOrders = createAsyncThunk(
  "sathaOrders/getAllSathaOrders",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await SathaOrdersEffects.getAllSathaOrders(
        payload ?? {}
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const sathaOrdersSlice = createSlice({
  name: "sathaOrders",
  initialState,
  extraReducers: (builder) => {
    // Get All Customer Satha Orders
    builder.addCase(getAllSathaOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllSathaOrders.fulfilled, (state, action) => {
      state.allSathaOrders = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllSathaOrders.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export default sathaOrdersSlice.reducer;
