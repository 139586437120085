import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CarsEffects from "./carsEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  searchCriteria: {
    nameAr: "",
    brand_id: "",
    brandType_id: "",
    manufacture_year: "",
    status: null,
  },
  allCars: [],
  addCarFormValues: {
    nameAr: "",
    nameEn: "",
    descriptionAr: "",
    descriptionEn: "",
    overviewAr: "",
    overviewEn: "",
    brand_id: "",
    brandType_id: "",
    order: "",
    status: "",
    manufacture_year: "",
    imageProfile: "",
    colors: [],
  },
  editCarFormValues: null,
  actionsResult: {},
  isLoading: false,
};

// Get All Cars
export const getAllCars = createAsyncThunk(
  "cars/getAllCar",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await CarsEffects.getAllCars(payload ?? {});
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Search Cars
export const searchCars = createAsyncThunk(
  "cars/searchCars",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { searchCriteria } = getState().cars;
      const response = await CarsEffects.getAllCars(searchCriteria);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Car
export const addCar = createAsyncThunk(
  "cars/addCar",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await CarsEffects.addCar(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Car
export const editCar = createAsyncThunk(
  "cars/editCar",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await CarsEffects.editCar(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Car
export const deleteCar = createAsyncThunk(
  "cars/deleteCar",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CarsEffects.deleteCar(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const carsSlice = createSlice({
  name: "cars",
  initialState,
  reducers: {
    patchEditCarFormValues: (state, action) => {
      state.editCarFormValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get All Cars
    builder.addCase(getAllCars.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCars.fulfilled, (state, action) => {
      state.allCars = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllCars.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add Car
    builder.addCase(addCar.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addCar.fulfilled, (state, action) => {
      state.actionsResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addCar.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit Car
    builder.addCase(editCar.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editCar.fulfilled, (state, action) => {
      state.actionsResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editCar.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Delete Car
    builder.addCase(deleteCar.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCar.fulfilled, (state, action) => {
      state.actionsResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "delete" });
    });
    builder.addCase(deleteCar.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditCarFormValues } = carsSlice.actions;
export default carsSlice.reducer;
