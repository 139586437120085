import React from "react";
import BrandsPanelSearch from "./BrandsPanelSearch";
import BrandsGrid from "./BrandsGrid";
import BrandsActions from "./BrandsActions";

function Brands() {
  return (
    <div>
      <BrandsActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <BrandsPanelSearch />
        <BrandsGrid />
      </div>
    </div>
  );
}

export default Brands;
