import axios from "axios";

class BranchesEffects {
  static controller = "Branches";

  // Get All Branches
  static getAllBranches = async (payload) => {
    const response = await axios.post(`/${this.controller}/getAll`, payload);
    return response.data;
  };

  // Add Branch
  static addBranch = async (payload) => {
    const response = await axios.post(`/${this.controller}/AddOne`, payload);
    return response.data;
  };

  // Edit Branch
  static editBranch = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/${payload.id}`,
      payload
    );
    return response.data;
  };
}

export default BranchesEffects;
