import React, { useEffect, useMemo } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  getAllWarnSigns,
  patchEditWarnSignFormValues,
} from "../../../redux/SystemManagement/WarnSigns/warnSignsSlice";

function WarnSignsGrid() {
  const dispatch = useDispatch();
  const { allWarnSigns, addOrEditResult, isLoading } = useSelector(
    (state) => state.warnSigns
  );

  // Get All Warn Signs
  useEffect(() => {
    dispatch(getAllWarnSigns());
  }, [addOrEditResult]);

  // Patch Warn Sign info
  const handleEditWarnSign = (row) => {
    dispatch(patchEditWarnSignFormValues(row));
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "العنوان",
        accessor: "nameAr",
      },
      {
        Header: "الوصف",
        accessor: "description_AR",
      },
      {
        Header: "الحالة",
        accessor: "status",
        Cell: ({ row }) =>
          row.original.status ? (
            <span className="uk-text-success" uk-icon="check"></span>
          ) : (
            <span className="uk-text-danger" uk-icon="close"></span>
          ),
      },
      {
        Header: "الترتيب",
        accessor: "order",
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <NavLink
              to="/WarnSigns/EditWarnSign"
              uk-tooltip="تعديل"
              className="uk-icon-button"
              uk-icon="pencil"
              onClick={() => handleEditWarnSign(row.original)}
            ></NavLink>
            <button
              uk-tooltip="حذف"
              className="uk-icon-button uk-margin-small-right"
              uk-icon="trash"
            ></button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <CustomReactTable
      data={allWarnSigns}
      columns={columns}
      isLoading={isLoading}
    />
  );
}

export default WarnSignsGrid;
