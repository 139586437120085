import React, { useEffect, useMemo } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  getAllNews,
  patchEditNewsFormValues,
} from "../../../redux/SystemManagement/News/newsSlice";

function NewsGrid() {
  const dispatch = useDispatch();
  const { allNews, addOrEditResult, isLoading } = useSelector(
    (state) => state.news
  );

  // Get All News
  useEffect(() => {
    dispatch(getAllNews());
  }, [addOrEditResult]);

  // Patch News info
  const handleEditNews = (row) => {
    dispatch(patchEditNewsFormValues(row));
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "العنوان",
        accessor: "nameAr",
      },
      {
        Header: "الوصف",
        accessor: "descriptionAr",
      },
      {
        Header: "الحالة",
        accessor: "status",
        Cell: ({ row }) =>
          row.original.status ? (
            <span className="uk-text-success" uk-icon="check"></span>
          ) : (
            <span className="uk-text-danger" uk-icon="close"></span>
          ),
      },
      {
        Header: "الترتيب",
        accessor: "order",
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <NavLink
              to="/News/EditNews"
              uk-tooltip="تعديل"
              className="uk-icon-button"
              uk-icon="pencil"
              onClick={() => handleEditNews(row.original)}
            ></NavLink>
            <button
              uk-tooltip="حذف"
              className="uk-icon-button uk-margin-small-right"
              uk-icon="trash"
            ></button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <CustomReactTable data={allNews} columns={columns} isLoading={isLoading} />
  );
}

export default NewsGrid;
