import React from "react";
import Select from "react-select";
import "./style.css";

function MaintenanceSelect({ field, error }) {
  const maintenanceOptions = [
    {
      value: true,
      label: "نعم",
    },
    {
      value: false,
      label: "لا",
    },
  ];

  return (
    <>
      <Select
        options={maintenanceOptions}
        placeholder="اختر"
        value={
          maintenanceOptions.find((x) => x.value === field.value) ?? field.value
        }
        onChange={(option) => field.onChange(option ? option.value : option)}
        aria-invalid={error ? "true" : "false"}
      />
      {error && (
        <small className="uk-text-danger uk-display-block uk-margin-small-top">
          <span className="uk-margin-small-left" uk-icon="warning"></span>
          {error.message}
        </small>
      )}
    </>
  );
}

export default MaintenanceSelect;
