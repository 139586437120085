import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../components/CustomInput/CustomInput";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { addUser } from "../../../redux/UsersManagement/Users/usersSlice";

function UsersActions({ rolesOptions }) {
  const dispatch = useDispatch();
  const { addUserModalValues } = useSelector((state) => state.users);

  // Toggle Show Password
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const toggleShowPassword = (e) => {
    e.preventDefault();

    setIsPasswordVisible(!isPasswordVisible);
  };

  const {
    formState: { errors, isValid },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: addUserModalValues,
  });

  const resetAddUserModalValues = () => {
    reset(addUserModalValues);
  };

  const handleAddUser = (data) => {
    dispatch(addUser(data));
    resetAddUserModalValues();
  };

  return (
    <>
      <nav className="uk-position-relative" aria-label="Breadcrumb">
        <ul className="uk-breadcrumb">
          <li>
            <span aria-current="page">المستخدمين</span>
          </li>
        </ul>
        <button
          className="uk-button uk-button-primary uk-button-small uk-border-rounded uk-position-left"
          uk-toggle="target: #addUserModal"
        >
          <span className="uk-margin-small-left" uk-icon="plus"></span>
          إنشاء
        </button>
      </nav>

      {/* Add User Modal */}
      <div id="ModalOfUser">
        <div
          id="addUserModal"
          uk-modal="bg-close: false"
          container="#ModalOfUser"
        >
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
              onClick={resetAddUserModalValues}
            ></button>
            <div className="uk-modal-header uk-text-center">
              <h2 className="uk-modal-title">إضافة مستخدم</h2>
            </div>
            <div className="uk-modal-body">
              <form className="uk-grid-small uk-child-width-1-2" uk-grid="">
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الإسم
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="name"
                      error={errors.name}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    رمز المستخدم
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="username"
                      error={errors.username}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الإيميل
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="email"
                      error={errors.email}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    كلمة المرور
                  </label>
                  <div className="uk-form-controls uk-inline uk-width-1-1 uk-margin-small-top">
                    <button
                      type="button"
                      style={{ height: 40 }}
                      className="uk-form-icon uk-form-icon-flip"
                      uk-icon={
                        isPasswordVisible ? "icon: eye-slash" : "icon: eye"
                      }
                      onClick={toggleShowPassword}
                    ></button>
                    <CustomInput
                      control={control}
                      name="password"
                      type={isPasswordVisible ? "text" : "password"}
                      error={errors.password}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الدور
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <Controller
                      name="roleId"
                      control={control}
                      render={({ field }) => (
                        <CustomSelect
                          placeholder={"اختر دور"}
                          field={field}
                          options={rolesOptions}
                          error={errors.roleId}
                        />
                      )}
                      rules={{
                        required: "هذا الحقل مطلوب!",
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="uk-modal-footer uk-text-left">
              <button
                className={`uk-button uk-button-secondary uk-margin-small-left uk-border-rounded${
                  isValid ? " uk-modal-close" : ""
                }`}
                type="button"
                onClick={handleSubmit(handleAddUser)}
              >
                إضافه
              </button>
              <button
                className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
                type="button"
                onClick={resetAddUserModalValues}
              >
                إلغاء
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsersActions;
