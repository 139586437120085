import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BrandsEffects from "./brandsEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  searchCriteria: {
    nameAr: "",
    status: null,
  },
  allBrands: [],
  addBrandModalValues: {
    nameAr: "",
    nameEn: "",
    order: "",
    status: "",
    viewinmaintenance: "",
  },
  editBrandModalValues: {
    nameAr: "",
    nameEn: "",
    order: "",
    status: "",
    viewinmaintenance: "",
  },
  addOrEditResult: {},
  isLoading: false,
};
// Get All Brands
export const getAllBrands = createAsyncThunk(
  "brands/getAllBrands",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await BrandsEffects.getAllBrands(payload ?? {});
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Brand
export const addBrand = createAsyncThunk(
  "brands/addBrand",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await BrandsEffects.addBrand(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Brand
export const editBrand = createAsyncThunk(
  "brands/editBrand",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await BrandsEffects.editBrand(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const brandsSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    patchEditBrandModal: (state, action) => {
      state.editBrandModalValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get All Brands
    builder.addCase(getAllBrands.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBrands.fulfilled, (state, action) => {
      state.allBrands = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllBrands.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add Brand
    builder.addCase(addBrand.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addBrand.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addBrand.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit Brand
    builder.addCase(editBrand.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editBrand.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editBrand.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditBrandModal } = brandsSlice.actions;
export default brandsSlice.reducer;
