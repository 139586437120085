import React from "react";
import { NavLink } from "react-router-dom";

function CarImagesActions({ carName }) {
  return (
    <nav className="uk-position-relative" aria-label="Breadcrumb">
      <ul className="uk-breadcrumb">
        <li>
          <NavLink to="/Cars">السيارات</NavLink>
        </li>
        <li>
          <span>{carName}</span>
        </li>
        <li>
          <span aria-current="page">الصور</span>
        </li>
      </ul>
    </nav>
  );
}

export default CarImagesActions;
