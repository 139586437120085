import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CategoriesEffects from "./categoriesEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  allCategories: [],
  allCategoriesByBrand: [],
  addCategoryFormValues: {
    nameAr: "",
    nameEn: "",
    status: "",
    order: "",
    price: "",
    engine_capacity: "",
    engine_power: "",
    traction_type: "",
    max_speed: "",
    cylinder: "",
    fuel_tank_capacity: "",
    fuel_economy: "",
    fuel_type: "",
    torque: "",
    n_of_seats: "",
    acceleration: "",
    length: "",
    width: "",
    height: "",
    wheelbase: "",
    car_trunk: "",
    pdf_file: null,
    file: null,
  },
  editCategoryFormValues: null,
  actionsResult: {},
  isLoading: false,
};

// Get All Categories
export const getAllCategories = createAsyncThunk(
  "categories/getAllCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await CategoriesEffects.getAllCategories();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Get All Categories by Car
export const getAllCategoriesByCar = createAsyncThunk(
  "categories/getAllCategoriesByCar",
  async (carId, { rejectWithValue }) => {
    try {
      const response = await CategoriesEffects.getAllCategoriesByCar(carId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Category
export const addCategory = createAsyncThunk(
  "categories/addCategory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await CategoriesEffects.addCategory(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Category
export const editCategory = createAsyncThunk(
  "categories/editCategory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await CategoriesEffects.editCategory(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Category
export const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CategoriesEffects.deleteCategory(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    patchEditCategoryFormValues: (state, action) => {
      let newState = {};
      Object.keys(action.payload).forEach((key) => {
        action.payload[key] === null
          ? (newState[key] = "")
          : (newState[key] = action.payload[key]);
      });

      state.editCategoryFormValues = newState;
    },
  },
  extraReducers: (builder) => {
    // Get All Categories
    builder.addCase(getAllCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      state.allCategories = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllCategories.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Get All Categories by Car
    builder.addCase(getAllCategoriesByCar.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCategoriesByCar.fulfilled, (state, action) => {
      state.allCategoriesByBrand = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllCategoriesByCar.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add Category
    builder.addCase(addCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addCategory.fulfilled, (state, action) => {
      state.actionsResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addCategory.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit Category
    builder.addCase(editCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editCategory.fulfilled, (state, action) => {
      state.actionsResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editCategory.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Delete Category
    builder.addCase(deleteCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.actionsResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "delete" });
    });
    builder.addCase(deleteCategory.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditCategoryFormValues } = categoriesSlice.actions;
export default categoriesSlice.reducer;
