import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import CustomInput from "../../../components/CustomInput/CustomInput";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { fuelOptions, tractionTypeOptions } from "../../../helpers/Utilities";
import StatusSelect from "../../../components/CustomSelect/StatusSelect";
import { editCategory } from "../../../redux/CarsManagement/Categories/categoriesSlice";

function EditCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editCategoryFormValues, isLoading } = useSelector(
    (state) => state.categories
  );

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    reset,
    control,
  } = useForm({
    defaultValues: editCategoryFormValues,
  });
  const carLuxuries = editCategoryFormValues?.cars_Luxury_Technology ?? [];

  // Handle upload files
  const [pdfFile, setPdfFile] = useState();
  const [excelFile, setExcelFile] = useState();

  const onFileChange = (file, name) => {
    name === "pdf_file" && setPdfFile(file);
    name === "file" && setExcelFile(file);
  };

  const handleDeleteSelectedFile = (e, name) => {
    e.preventDefault();
    if (name === "pdf_file") {
      setPdfFile(null);
      setValue("pdf_file", null);
    }
    if (name === "file") {
      setExcelFile(null);
      setValue("file", null);
    }
  };

  useEffect(() => {
    reset(editCategoryFormValues);
  }, [editCategoryFormValues]);

  const handleEditCategory = (data) => {
    const formData = new FormData();

    for (let key in data) {
      (key === "pdf_file" && pdfFile) || (key === "file" && excelFile)
        ? formData.append(key, data[key][0])
        : formData.append(key, data[key]);
    }

    dispatch(editCategory({ formData, id: data.id })).then(
      (res) => !res.error && navigate(-1)
    );
  };

  // Redirect if there is no category to edit
  useEffect(() => {
    !editCategoryFormValues && navigate(-1);
  }, [editCategoryFormValues]);

  return (
    <>
      <nav className="uk-position-relative" aria-label="Breadcrumb">
        <ul className="uk-breadcrumb">
          <li>
            <NavLink to="/Cars">السيارات</NavLink>
          </li>
          <li>
            <span>الفئات</span>
          </li>
          <li>
            <span aria-current="page">تعديل فئة</span>
          </li>
        </ul>
      </nav>

      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <form className="uk-grid-small uk-child-width-1-3" uk-grid="">
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الإسم بالعربي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="nameAr"
                error={errors.nameAr}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الإسم بالإنجليزي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="nameEn"
                error={errors.nameEn}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الترتيب
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="order"
                error={errors.order}
                type="number"
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الحالة
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <StatusSelect field={field} error={errors.status} />
                )}
                rules={{
                  validate: (v) => typeof v == "boolean" || "هذا الحقل مطلوب!",
                }}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              السعر
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="price"
                error={errors.price}
                type="number"
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              سعة المحرك
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="engine_capacity"
                error={errors.engine_capacity}
                type="number"
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              قوة المحرك
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="engine_power"
                error={errors.engine_power}
                type="number"
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              ناقل الحركة
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <Controller
                name="traction_type"
                control={control}
                render={({ field }) => (
                  <CustomSelect
                    field={field}
                    options={tractionTypeOptions}
                    error={errors.traction_type}
                  />
                )}
                rules={{
                  required: "هذا الحقل مطلوب!",
                }}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              السرعة القصوى
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="max_speed"
                error={errors.max_speed}
                type="number"
                required={false}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              سيلندر
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="cylinder"
                error={errors.cylinder}
                type="number"
                required={false}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              سعة خزان الوقود
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="fuel_tank_capacity"
                error={errors.fuel_tank_capacity}
                type="number"
                required={false}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الإقتصاد في استهلاك الوقود (كم/لتر)
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="fuel_economy"
                error={errors.fuel_economy}
                type="number"
                required={false}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              نوع الوقود
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <Controller
                name="fuel_type"
                control={control}
                render={({ field }) => (
                  <CustomSelect
                    field={field}
                    options={fuelOptions}
                    error={errors.fuel_type}
                  />
                )}
                rules={{
                  required: "هذا الحقل مطلوب!",
                }}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              عزم الدوران
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="torque"
                error={errors.torque}
                type="number"
                required={false}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              عدد المقاعد
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="n_of_seats"
                  error={errors.n_of_seats}
                  type="number"
                  required={false}
                />
              </div>
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              التسارع 0-100 كم/ساعة (ثانية)
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="acceleration"
                error={errors.acceleration}
                type="number"
                required={false}
              />
            </div>
          </div>

          <h4 className="uk-heading-line uk-margin-medium-top uk-margin-small-bottom uk-width-1-1 uk-text-bold">
            <span>القياسات والطول</span>
          </h4>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الطول (متر)
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="length"
                error={errors.length}
                type="number"
                required={false}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              العرض (متر)
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="width"
                error={errors.width}
                type="number"
                required={false}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الإرتفاع (متر)
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="height"
                error={errors.height}
                type="number"
                required={false}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              قاعدة العجلات (متر)
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="wheelbase"
                error={errors.wheelbase}
                type="number"
                required={false}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              سعة صندوق السيارة
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="car_trunk"
                error={errors.car_trunk}
                type="number"
                required={false}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الملف
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <div uk-form-custom="">
                <input
                  type="file"
                  aria-label="Custom controls"
                  {...register("pdf_file", {
                    onChange: (e) =>
                      onFileChange(e.target.files[0], "pdf_file"),
                    validate: pdfFile && {
                      acceptedFormats: (files) =>
                        ["application/pdf"].includes(files[0]?.type) ||
                        "صيغة الملف غير صحيحة!",
                    },
                  })}
                  aria-invalid={errors.pdf_file ? "true" : "false"}
                />
                <button
                  className="uk-button uk-button-default uk-border-rounded"
                  type="button"
                  tabIndex="-1"
                >
                  إختر ملف pdf
                </button>
              </div>
              {pdfFile && (
                <small className="uk-text-success uk-display-block uk-margin-small-top">
                  <button
                    uk-tooltip="حذف"
                    className="uk-icon-button uk-margin-small-left uk-text-danger"
                    uk-icon="trash"
                    onClick={(e) => handleDeleteSelectedFile(e, "pdf_file")}
                  ></button>
                  {pdfFile.name}
                </small>
              )}
              {errors.pdf_file && (
                <small className="uk-text-danger uk-display-block uk-margin-small-top">
                  <span
                    className="uk-margin-small-left"
                    uk-icon="warning"
                  ></span>
                  {errors.pdf_file.message}
                </small>
              )}
            </div>
          </div>

          <h4 className="uk-heading-line uk-margin-medium-top uk-margin-small-bottom uk-width-1-1 uk-text-bold">
            <span>رفاهية وتكنولوجيا</span>
          </h4>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              ملف Excel
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <div uk-form-custom="">
                <input
                  type="file"
                  aria-label="Custom controls"
                  {...register("file", {
                    onChange: (e) => onFileChange(e.target.files[0], "file"),
                    validate: excelFile && {
                      acceptedFormats: (files) =>
                        [
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        ].includes(files[0]?.type) || "صيغة الملف غير صحيحة!",
                    },
                  })}
                  aria-invalid={errors.file ? "true" : "false"}
                />
                <button
                  className="uk-button uk-button-default uk-border-rounded"
                  type="button"
                  tabIndex="-1"
                >
                  إختر ملف Excel
                </button>
              </div>
              {excelFile && (
                <small className="uk-text-success uk-display-block uk-margin-small-top">
                  <button
                    uk-tooltip="حذف"
                    className="uk-icon-button uk-margin-small-left uk-text-danger"
                    uk-icon="trash"
                    onClick={(e) => handleDeleteSelectedFile(e, "file")}
                  ></button>
                  {excelFile.name}
                </small>
              )}
              {errors.file && (
                <small className="uk-text-danger uk-display-block uk-margin-small-top">
                  <span
                    className="uk-margin-small-left"
                    uk-icon="warning"
                  ></span>
                  {errors.file.message}
                </small>
              )}
            </div>
          </div>

          {/* Load All Luxuries */}
          <div
            className="uk-width-1-1 uk-grid-small uk-child-width-1-2"
            uk-grid=""
          >
            {carLuxuries.map((luxury, i) => (
              <div key={i}>
                <div className="uk-flex uk-flex-right uk-flex-top">
                  <span className="uk-margin-small-left">-</span>
                  <span>{luxury.luxury_technologyAR}</span>
                </div>
              </div>
            ))}
          </div>
        </form>
        <div className="uk-margin-top uk-text-left">
          <button
            className="uk-button uk-button-secondary uk-margin-small-left uk-border-rounded"
            type="button"
            disabled={!isDirty || isLoading}
            onClick={handleSubmit(handleEditCategory)}
          >
            {isLoading ? <div uk-spinner="ratio: 0.5"></div> : "تعديل"}
          </button>
          <button
            className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
            type="button"
            onClick={() => navigate(-1)}
          >
            إلغاء
          </button>
        </div>
      </div>
    </>
  );
}

export default EditCategory;
