import axios from "axios";

class UserEffects {
  static controller = "Auth";

  // Login
  static userLogin = async (payload) => {
    const response = await axios.post(`/${this.controller}/token`, payload);
    return response.data;
  };

  // Check Access Token
  static getCurrentUser = async () => {
    const response = await axios.get(`/${this.controller}/current-user`);
    return response.data;
  };

  // Revoke Access Token
  static revokeToken = async (token) => {
    const response = await axios.post(`/${this.controller}/revokeToken`, {
      token,
    });
    return response.data;
  };
}

export default UserEffects;
