import React from "react";
import MaintenanceOffersActions from "./MaintenanceOffersActions";
import MaintenanceOffersGrid from "./MaintenanceOffersGrid";

function MaintenanceOffers() {
  return (
    <div>
      <MaintenanceOffersActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <MaintenanceOffersGrid />
      </div>
    </div>
  );
}

export default MaintenanceOffers;
