import axios from "axios";

class SathaOrdersEffects {
  static controller = "Maintenance_order_myplaces";

  // Get All Customer Satha Orders
  static getAllSathaOrders = async (payload) => {
    const response = await axios.post(`/${this.controller}/getAll`, payload);
    return response.data;
  };
}

export default SathaOrdersEffects;
