import React, { useEffect, useMemo } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  editAdvice,
  getAllAdvices,
  patchEditAdviceModal,
} from "../../../redux/SystemManagement/Advices/advicesSlice";
import StatusSelect from "../../../components/CustomSelect/StatusSelect";
import CustomInput from "../../../components/CustomInput/CustomInput";

function AdvicesGrid() {
  const dispatch = useDispatch();
  const { allAdvices, addOrEditResult, editAdviceModalValues, isLoading } =
    useSelector((state) => state.advices);

  const {
    formState: { errors, isValid, isDirty },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: editAdviceModalValues,
  });

  const resetEditAdviceModalValues = () => {
    reset(editAdviceModalValues);
  };

  useEffect(() => {
    resetEditAdviceModalValues();
  }, [editAdviceModalValues, reset]);

  const handleAdviceModalValues = (row) => {
    const payload = {
      id: row.id,
      nameAr: row.nameAr,
      nameEn: row.nameEn,
      descriptionAr: row.descriptionAr,
      descriptionEn: row.descriptionEn,
      order: row.order,
      status: row.status,
    };
    dispatch(patchEditAdviceModal(payload));
  };

  const handleEditAdvice = (data) => {
    const payload = {
      id: data.id,
      ...data,
    };
    dispatch(editAdvice(payload));
  };

  // Get All Advices
  useEffect(() => {
    dispatch(getAllAdvices());
  }, [addOrEditResult]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "العنوان",
        accessor: "nameAr",
      },
      {
        Header: "الوصف",
        accessor: "descriptionAr",
      },
      {
        Header: "الحالة",
        accessor: "status",
        Cell: ({ row }) =>
          row.original.status ? (
            <span className="uk-text-success" uk-icon="check"></span>
          ) : (
            <span className="uk-text-danger" uk-icon="close"></span>
          ),
      },
      {
        Header: "الترتيب",
        accessor: "order",
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <button
              uk-tooltip="تعديل"
              className="uk-icon-button"
              uk-icon="pencil"
              uk-toggle="target: #editAdviceModal"
              onClick={() => handleAdviceModalValues(row.original)}
            ></button>
            <button
              uk-tooltip="حذف"
              className="uk-icon-button uk-margin-small-right"
              uk-icon="trash"
            ></button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      {/* Edit Advice Modal */}
      <div id="ModalOfEditAdvice">
        <div
          id="editAdviceModal"
          uk-modal="bg-close: false"
          container="#ModalOfEditAdvice"
        >
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
              onClick={resetEditAdviceModalValues}
            ></button>
            <div className="uk-modal-header uk-text-center">
              <h2 className="uk-modal-title">تعديل</h2>
            </div>
            <div className="uk-modal-body">
              <form className="uk-grid-small uk-child-width-1-2" uk-grid="">
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    العنوان بالعربي
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="nameAr"
                      error={errors.nameAr}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    العنوان بالإنجليزي
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="nameEn"
                      error={errors.nameEn}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الوصف بالعربي
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="descriptionAr"
                      error={errors.descriptionAr}
                      textarea
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الوصف بالإنجليزي
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="descriptionEn"
                      error={errors.descriptionEn}
                      textarea
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الترتيب
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="order"
                      error={errors.order}
                      type="number"
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الحالة
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <StatusSelect field={field} error={errors.status} />
                      )}
                      rules={{
                        validate: (v) =>
                          typeof v == "boolean" || "هذا الحقل مطلوب!",
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="uk-modal-footer uk-text-left">
              <button
                className={`uk-button uk-button-secondary uk-margin-small-left uk-border-rounded${
                  isValid ? " uk-modal-close" : ""
                }`}
                type="button"
                disabled={!isDirty}
                onClick={handleSubmit(handleEditAdvice)}
              >
                تعديل
              </button>
              <button
                className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
                type="button"
                onClick={resetEditAdviceModalValues}
              >
                إلغاء
              </button>
            </div>
          </div>
        </div>
      </div>
      <CustomReactTable
        data={allAdvices}
        columns={columns}
        isLoading={isLoading}
      />
    </>
  );
}

export default AdvicesGrid;
