import React, { useEffect, useMemo } from "react";
import CustomReactTable from "../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  editBank,
  getAllBanks,
  patchEditBankModal,
} from "../../redux/Banks/banksSlice";
import StatusSelect from "../../components/CustomSelect/StatusSelect";
import CustomInput from "../../components/CustomInput/CustomInput";

function BanksGrid() {
  const dispatch = useDispatch();
  const { allBanks, editBankModalValues, isLoading, addOrEditResult } =
    useSelector((state) => state.banks);

  const {
    formState: { errors, isValid, isDirty },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: editBankModalValues,
  });

  const resetEditBankModalValues = () => {
    reset(editBankModalValues);
  };

  useEffect(() => {
    resetEditBankModalValues();
  }, [editBankModalValues, reset]);

  const handleBankModalValues = (row) => {
    dispatch(patchEditBankModal(row));
  };

  const handleEditBank = (data) => {
    const payload = {
      id: data.id,
      ...data,
    };
    dispatch(editBank(payload));
  };

  // Get All Banks
  useEffect(() => {
    dispatch(getAllBanks());
  }, [addOrEditResult]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "اسم البنك",
        accessor: "nameAr",
      },
      {
        Header: "نسبة الربح",
        accessor: "finance_rate",
      },
      {
        Header: "نسبة التأمين",
        accessor: "insurance_rate",
      },
      {
        Header: "نسبة المشاركة",
        accessor: "ownership_rate",
      },
      {
        Header: "الرسوم الأدارية",
        accessor: "administrative_fees",
      },
      {
        Header: "الحالة",
        accessor: "status",
        Cell: ({ row }) =>
          row.original.status ? (
            <span className="uk-text-success" uk-icon="check"></span>
          ) : (
            <span className="uk-text-danger" uk-icon="close"></span>
          ),
      },
      {
        Header: "الترتيب",
        accessor: "order",
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <button
              uk-tooltip="تعديل"
              className="uk-icon-button"
              uk-icon="pencil"
              uk-toggle="target: #editBankModal"
              onClick={() => handleBankModalValues(row.original)}
            ></button>
            <button
              uk-tooltip="حذف"
              className="uk-icon-button uk-margin-small-right"
              uk-icon="trash"
            ></button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      {/* Edit Bank Modal */}
      <div id="ModalOfEditBank">
        <div
          id="editBankModal"
          uk-modal="bg-close: false"
          container="#ModalOfEditBank"
        >
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
              onClick={resetEditBankModalValues}
            ></button>
            <div className="uk-modal-header uk-text-center">
              <h2 className="uk-modal-title">تعديل</h2>
            </div>
            <div className="uk-modal-body">
              <form className="uk-grid-small uk-child-width-1-2" uk-grid="">
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الإسم بالعربي
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="nameAr"
                      error={errors.nameAr}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الإسم بالإنجليزي
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="nameEn"
                      error={errors.nameEn}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    نسبة الربح
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="finance_rate"
                      error={errors.finance_rate}
                      type="number"
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    نسبة التأمين
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="insurance_rate"
                      error={errors.insurance_rate}
                      type="number"
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    نسبة المشاركة
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="ownership_rate"
                      error={errors.ownership_rate}
                      type="number"
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الرسوم الإدارية
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="administrative_fees"
                      error={errors.administrative_fees}
                      type="number"
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الترتيب
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="order"
                      error={errors.order}
                      type="number"
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الحالة
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <StatusSelect field={field} error={errors.status} />
                      )}
                      rules={{
                        validate: (v) =>
                          typeof v == "boolean" || "هذا الحقل مطلوب!",
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="uk-modal-footer uk-text-left">
              <button
                className={`uk-button uk-button-secondary uk-margin-small-left uk-border-rounded${
                  isValid ? " uk-modal-close" : ""
                }`}
                type="button"
                disabled={!isDirty}
                onClick={handleSubmit(handleEditBank)}
              >
                تعديل
              </button>
              <button
                className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
                type="button"
                onClick={resetEditBankModalValues}
              >
                إلغاء
              </button>
            </div>
          </div>
        </div>
      </div>
      <CustomReactTable
        data={allBanks}
        columns={columns}
        isLoading={isLoading}
      />
    </>
  );
}

export default BanksGrid;
