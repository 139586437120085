import axios from "axios";

class CategoriesEffects {
  static controller = "Option";

  // Get All Categories
  static getAllCategories = async () => {
    const response = await axios.get(`/${this.controller}/getAll`);
    return response.data;
  };

  // Get All Categories by Car
  static getAllCategoriesByCar = async (carId) => {
    const response = await axios.get(
      `/${this.controller}/GetOptionsOfCar/${carId}`
    );
    return response.data;
  };

  // Add Category
  static addCategory = async (payload) => {
    const response = await axios.post(`/${this.controller}/AddOne`, payload);
    return response.data;
  };

  // Edit Category
  static editCategory = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/${payload.id}`,
      payload.formData
    );
    return response.data;
  };

  // Delete Category
  static deleteCategory = async (id) => {
    const response = await axios.put(`/${this.controller}/DeleteModel/${id}`);
    return response.data;
  };
}

export default CategoriesEffects;
