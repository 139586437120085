import React from "react";
import { Controller } from "react-hook-form";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "./style.css";

function CustomTimePicker({
  name,
  control,
  error,
  onChange,
  allowClear,
  format = "hh:mm a",
  required = true,
}) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TimePicker
            value={field.value}
            format={format}
            locale="ar"
            clearIcon={allowClear}
            onChange={(time) => {
              field.onChange(time);
              onChange && onChange(time);
            }}
          />
        )}
        rules={{
          required: required && "هذا الحقل مطلوب!",
        }}
      />
      {error && (
        <small className="uk-text-danger uk-display-block uk-margin-small-top">
          <span className="uk-margin-small-left" uk-icon="warning"></span>
          {error.message}
        </small>
      )}
    </>
  );
}

export default CustomTimePicker;
