import axios from "axios";

class BrandTypesEffects {
  static controller = "brand_types";

  // Search Brand Types
  static searchBrandTypes = async (payload) => {
    const response = await axios.post(`/${this.controller}/getAll`, payload);
    return response.data;
  };

  // Get All Brand Types
  static getAllBrandTypes = async (brandId) => {
    const response = await axios.get(
      `/${this.controller}/getAllByBrands/${brandId}`
    );
    return response.data;
  };

  // Add Brand Type
  static addBrandType = async (payload) => {
    const response = await axios.post(`/${this.controller}/AddOne`, payload);
    return response.data;
  };

  // Edit Brand Type
  static editBrandType = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/${payload.id}`,
      payload
    );
    return response.data;
  };
}

export default BrandTypesEffects;
