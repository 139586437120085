import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import StatusSelect from "../../../components/CustomSelect/StatusSelect";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { editNews } from "../../../redux/SystemManagement/News/newsSlice";
import { base_url } from "../../../helpers/Constants";

function EditNews() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editNewsFormValues, isLoading } = useSelector((state) => state.news);

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: editNewsFormValues,
  });

  useEffect(() => {
    reset(editNewsFormValues);
  }, [editNewsFormValues]);

  // Preview selected image
  const [image, setImage] = useState();
  const handleImageChange = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleEditNews = (data) => {
    const formData = new FormData();

    for (let key in data) {
      key === "image"
        ? formData.append(key, data[key][0])
        : formData.append(key, data[key]);
    }

    dispatch(editNews({ formData, id: data.id })).then(
      (res) => !res.error && navigate("/News")
    );
  };

  return (
    <>
      <nav className="uk-position-relative" aria-label="Breadcrumb">
        <ul className="uk-breadcrumb">
          <li>
            <span>إدارة نظام التحكم</span>
          </li>
          <li>
            <NavLink to="/News">الأخبار</NavLink>
          </li>
          <li>
            <span aria-current="page">تعديل</span>
          </li>
        </ul>
      </nav>

      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <form className="uk-grid-small uk-child-width-1-2" uk-grid="">
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              العنوان بالعربي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="nameAr"
                error={errors.nameAr}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              العنوان بالإنجليزي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="nameEn"
                error={errors.nameEn}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الوصف بالعربي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="descriptionAr"
                error={errors.descriptionAr}
                textarea
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الوصف بالإنجليزي
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="descriptionEn"
                error={errors.descriptionEn}
                textarea
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الترتيب
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="order"
                error={errors.order}
                type="number"
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الحالة
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <StatusSelect field={field} error={errors.status} />
                )}
                rules={{
                  validate: (v) => typeof v == "boolean" || "هذا الحقل مطلوب!",
                }}
              />
            </div>
          </div>
          <div>
            <label className="uk-form-label uk-text-gray uk-text-bold">
              الصورة
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <div uk-form-custom="">
                <input
                  type="file"
                  aria-label="Custom controls"
                  name="image"
                  {...register("image", {
                    onChange: handleImageChange,
                    validate: image && {
                      lessThan2MB: (files) =>
                        files[0]?.size < 2000000 ||
                        "لا يجب أن يتعدى حجم الصورة 2 ميجا بايت",
                      acceptedFormats: (files) =>
                        ["image/jpg", "image/png", "image/jpeg"].includes(
                          files[0]?.type
                        ) || "يجب أن تكون صيغة الصورة JPG, PNG, JPEG",
                    },
                  })}
                  aria-invalid={errors.image ? "true" : "false"}
                />
                <button
                  className="uk-button uk-button-default uk-border-rounded"
                  type="button"
                  tabIndex="-1"
                >
                  إختر صورة
                </button>
                <img
                  src={image ?? `${base_url}/${editNewsFormValues?.image}`}
                  alt="Selected image"
                  className="uk-height-max-small uk-border-rounded uk-margin-small-right uk-align-left"
                />
              </div>
              {errors.image && (
                <small className="uk-text-danger uk-display-block uk-margin-small-top">
                  <span
                    className="uk-margin-small-left"
                    uk-icon="warning"
                  ></span>
                  {errors.image.message}
                </small>
              )}
            </div>
          </div>
        </form>
        <div className="uk-margin-top uk-text-left">
          <button
            className="uk-button uk-button-secondary uk-margin-small-left uk-border-rounded"
            type="button"
            disabled={!isDirty || isLoading}
            onClick={handleSubmit(handleEditNews)}
          >
            {isLoading ? <div uk-spinner="ratio: 0.5"></div> : "تعديل"}
          </button>
          <NavLink
            to="/News"
            className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
            type="button"
          >
            إلغاء
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default EditNews;
