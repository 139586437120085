import axios from "axios";

class WarnSignsEffects {
  static controller = "warn_signs";

  // Get All Warn Signs
  static getAllWarnSigns = async () => {
    const response = await axios.get(`/${this.controller}/getAll`);
    return response.data;
  };

  // Add Warn Sign
  static addWarnSign = async (payload) => {
    const response = await axios.post(`/${this.controller}/AddOne`, payload);
    return response.data;
  };

  // Edit Warn Sign
  static editWarnSign = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/${payload.id}`,
      payload.formData
    );
    return response.data;
  };
}

export default WarnSignsEffects;
