export const manufacturingYears = () => {
  let startYear = 2010;
  let currentYear = new Date().getFullYear() + 1;
  let years = [];

  while (currentYear >= startYear) {
    years.push({ value: startYear, label: startYear });
    startYear++;
  }

  return years;
};

export const fuelOptions = [
  {
    value: 0,
    label: "بنزين",
  },
  {
    value: 1,
    label: "سولار",
  },
  {
    value: 2,
    label: "كهرباء",
  },
  {
    value: 3,
    label: "هجين",
  },
];

export const tractionTypeOptions = [
  {
    value: 0,
    label: "أوتوماتيك",
  },
  {
    value: 1,
    label: "مانيوال",
  },
];

export const colorOptions = [
  {
    id: 1,
    value: "#FFFFFF",
    label: "أبيض",
  },
  {
    id: 2,
    value: "#FFD700",
    label: "ذهبي",
  },
  {
    id: 3,
    value: "#FF0000",
    label: "أحمر",
  },
  {
    id: 4,
    value: "#0000FF",
    label: "أزرق",
  },
  {
    id: 5,
    value: "#000000",
    label: "أسود",
  },
  {
    id: 6,
    value: "#C0C0C0",
    label: "رمادي",
  },
  {
    id: 7,
    value: "#00FF00",
    label: "أخضر",
  },
  {
    id: 8,
    value: "#A0522D",
    label: "بني",
  },
];
