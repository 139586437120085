import React from "react";
import Select from "react-select";
import "./style.css";

function StatusSelect({ field, error, allowClear = false, allowAll = false }) {
  const statusOptions = allowAll
    ? [
        {
          value: "",
          label: "الكل",
        },
        {
          value: true,
          label: "مُفعل",
        },
        {
          value: false,
          label: "غير مُفعل",
        },
      ]
    : [
        {
          value: true,
          label: "مُفعل",
        },
        {
          value: false,
          label: "غير مُفعل",
        },
      ];

  return (
    <>
      <Select
        options={statusOptions}
        isClearable={allowClear}
        placeholder="اختر"
        value={
          statusOptions.find((x) => x.value === field.value) ?? field.value
        }
        onChange={(option) => field.onChange(option ? option.value : option)}
        aria-invalid={error ? "true" : "false"}
      />
      {error && (
        <small className="uk-text-danger uk-display-block uk-margin-small-top">
          <span className="uk-margin-small-left" uk-icon="warning"></span>
          {error.message}
        </small>
      )}
    </>
  );
}

export default StatusSelect;
