import React from "react";
import CustomerOrdersActions from "./CustomerOrdersActions";
import CustomerOrdersGrid from "./CustomerOrdersGrid";
import CustomerOrdersPanelSearch from "./CustomerOrdersPanelSearch";

function CustomerOrders() {
  return (
    <div>
      <CustomerOrdersActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <CustomerOrdersPanelSearch />
        <CustomerOrdersGrid />
      </div>
    </div>
  );
}

export default CustomerOrders;
