import React from "react";
import logo from "../../assets/imgs/logo/logo.png";
import miniLogo from "../../assets/imgs/logo/mini_logo.png";
import { NavLink } from "react-router-dom";
import useUserPermissions from "../../hooks/useUserPermissions";

function SideBar() {
  const { isUserAllowed } = useUserPermissions();
  const viewFullSidebar = () => {
    document
      .getElementById("main-sidebar")
      ?.classList.remove("minimized-sidebar");
  };

  return (
    <aside id="main-sidebar">
      <div className="logo">
        <NavLink to="/">
          <img className="full-logo" src={logo} alt="Aldahyan Logo" />
          <img className="mini-logo" src={miniLogo} alt="Aldahyan Logo" />
        </NavLink>
      </div>
      <div>
        <ul className="uk-nav-default uk-nav-divider" uk-nav="">
          <li>
            <NavLink to="/">
              <i className="fi fi-rr-apps uk-margin-small-left"></i>
              <span className="title">لوحة التحكم</span>
            </NavLink>
          </li>
          {isUserAllowed("Banks") && (
            <li>
              <NavLink to="/Banks">
                <i className="fi fi-rr-hand-holding-usd uk-margin-small-left"></i>
                <span className="title">الممولين</span>
              </NavLink>
            </li>
          )}
          {isUserAllowed("UserManagement") && (
            <li className="uk-parent">
              <a href="#" onClick={viewFullSidebar}>
                <i className="fi fi-rr-user uk-margin-small-left"></i>
                <span className="title">إدارة المستخدمين</span>
                <span uk-nav-parent-icon=""></span>
              </a>
              <ul className="uk-nav-sub">
                <li>
                  <NavLink to="/Users">المستخدمين</NavLink>
                </li>
                <li>
                  <NavLink to="/Roles">الأدوار</NavLink>
                </li>
              </ul>
            </li>
          )}
          {isUserAllowed("Cars") && (
            <li className="uk-parent">
              <a href="#" onClick={viewFullSidebar}>
                <i className="fi fi-rr-car uk-margin-small-left"></i>
                <span className="title">السيارات</span>
                <span uk-nav-parent-icon=""></span>
              </a>
              <ul className="uk-nav-sub">
                <li>
                  <NavLink to="/Brands">المُصنع</NavLink>
                </li>

                <li>
                  <NavLink to="/Cars">السيارات</NavLink>
                </li>
                <li>
                  <NavLink to="/FinanceOrders">طلبات تمويل السيارات</NavLink>
                </li>
                <li>
                  <NavLink to="/Offers">عروض السيارات</NavLink>
                </li>
              </ul>
            </li>
          )}
          {isUserAllowed("Maintenance") && (
            <li className="uk-parent">
              <a href="#" onClick={viewFullSidebar}>
                <i className="fi fi-tr-car-mechanic uk-margin-small-left"></i>
                <span className="title">الصيانة</span>
                <span uk-nav-parent-icon=""></span>
              </a>
              <ul className="uk-nav-sub">
                <li>
                  <NavLink to="/MaintenanceTypes">أنواع الصيانة</NavLink>
                </li>
                <li>
                  <NavLink to="/MaintenanceOffers">عروض الصيانة</NavLink>
                </li>
                <li>
                  <NavLink to="/CustomerMtcOrders">طلبات الصيانة</NavLink>
                </li>
                <li>
                  <NavLink to="/SathaOrders">طلبات السطحة</NavLink>
                </li>
              </ul>
            </li>
          )}
          {isUserAllowed("SystemManagement") && (
            <li className="uk-parent">
              <a href="#" onClick={viewFullSidebar}>
                <i className="fi fi-rs-puzzle-piece uk-margin-small-left"></i>
                <span className="title">إدارة نظام التحكم</span>
                <span uk-nav-parent-icon=""></span>
              </a>
              <ul className="uk-nav-sub">
                <li>
                  <NavLink to="/SystemManagement/Advices">
                    النصائح والإرشادات
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/Branches">الفروع</NavLink>
                </li>
                <li>
                  <NavLink to="/WarnSigns">العلامات التحذيرية</NavLink>
                </li>
                <li>
                  <NavLink to="/News">الأخبار</NavLink>
                </li>
                <li>
                  <NavLink to="/ContactMessages">رسائل التواصل</NavLink>
                </li>
              </ul>
            </li>
          )}
        </ul>
      </div>
    </aside>
  );
}

export default SideBar;
