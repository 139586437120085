import axios from "axios";

class BrandsEffects {
  static controller = "Brands";

  // Get All Brands
  static getAllBrands = async (payload) => {
    const response = await axios.post(`/${this.controller}/getAll`, payload);
    return response.data;
  };

  // Add Brand
  static addBrand = async (payload) => {
    const response = await axios.post(`/${this.controller}/AddOne`, payload);
    return response.data;
  };

  // Edit Brand
  static editBrand = async (payload) => {
    const response = await axios.put(
      `/${this.controller}/${payload.id}`,
      payload
    );
    return response.data;
  };
}

export default BrandsEffects;
