import React, { useEffect, useMemo } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import {
  editBrand,
  getAllBrands,
  patchEditBrandModal,
} from "../../../redux/CarsManagement/Brands/brandsSlice";
import { Controller, useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import StatusSelect from "../../../components/CustomSelect/StatusSelect";
import MaintenanceSelect from "../../../components/CustomSelect/MaintenanceSelect";
import CustomInput from "../../../components/CustomInput/CustomInput";

function BrandsGrid() {
  const dispatch = useDispatch();
  const { allBrands, editBrandModalValues, isLoading, addOrEditResult } =
    useSelector((state) => state.brands);

  const {
    formState: { errors, isValid, isDirty },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: editBrandModalValues,
  });

  const resetEditBrandModalValues = () => {
    reset(editBrandModalValues);
  };

  useEffect(() => {
    resetEditBrandModalValues();
  }, [editBrandModalValues]);

  const handleBrandModalValues = (row) => {
    const payload = {
      id: row.id,
      nameAr: row.nameAr,
      nameEn: row.nameEn,
      order: row.order,
      status: row.status,
      viewinmaintenance: row.viewinmaintenance,
    };
    dispatch(patchEditBrandModal(payload));
  };

  const handleEditBrand = (data) => {
    const payload = {
      id: data.id,
      nameAr: data.nameAr,
      nameEn: data.nameEn,
      order: data.order,
      status: data.status,
      viewinmaintenance: data.viewinmaintenance,
    };
    dispatch(editBrand(payload));
  };

  // Get All Brands
  useEffect(() => {
    dispatch(getAllBrands());
  }, [addOrEditResult]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "الإسم",
        accessor: "nameAr",
      },
      {
        Header: "النوع",
        Cell: ({ row }) => (
          <NavLink
            className="uk-button uk-button-link uk-border-rounded uk-text-secondary uk-text-bold"
            to="/Brands/BrandTypes"
            state={{ id: row.original.id, brandName: row.original.nameAr }}
          >
            عرض
          </NavLink>
        ),
      },
      {
        Header: "الحالة",
        accessor: "status",
        Cell: ({ row }) =>
          row.original.status ? (
            <span className="uk-text-success" uk-icon="check"></span>
          ) : (
            <span className="uk-text-danger" uk-icon="close"></span>
          ),
      },
      {
        Header: "الترتيب",
        accessor: "order",
      },
      {
        Header: "عرض في الصيانة",
        accessor: "viewinmaintenance",
        Cell: ({ row }) =>
          row.original.viewinmaintenance ? (
            <span className="uk-text-success" uk-icon="check"></span>
          ) : (
            <span className="uk-text-danger" uk-icon="close"></span>
          ),
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <button
              uk-tooltip="تعديل"
              className="uk-icon-button"
              uk-icon="pencil"
              uk-toggle="target: #editBrandModal"
              onClick={() => handleBrandModalValues(row.original)}
            ></button>
            <button
              uk-tooltip="حذف"
              className="uk-icon-button uk-margin-small-right"
              uk-icon="trash"
            ></button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      {/* Edit Brand Modal */}
      <div id="ModalOfEditBrand">
        <div
          id="editBrandModal"
          uk-modal="bg-close: false"
          container="#ModalOfEditBrand"
        >
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
              onClick={resetEditBrandModalValues}
            ></button>
            <div className="uk-modal-header uk-text-center">
              <h2 className="uk-modal-title">تعديل</h2>
            </div>
            <div className="uk-modal-body">
              <form className="uk-grid-small uk-child-width-1-2" uk-grid="">
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الإسم بالعربي
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="nameAr"
                      error={errors.nameAr}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الإسم بالإنجليزي
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="nameEn"
                      error={errors.nameEn}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الترتيب
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <CustomInput
                      control={control}
                      name="order"
                      error={errors.order}
                      type="number"
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    الحالة
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <StatusSelect field={field} error={errors.status} />
                      )}
                      rules={{
                        validate: (v) =>
                          typeof v == "boolean" || "هذا الحقل مطلوب!",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <label className="uk-form-label uk-text-gray uk-text-bold">
                    عرض في الصيانة
                  </label>
                  <div className="uk-form-controls uk-margin-small-top">
                    <Controller
                      name="viewinmaintenance"
                      control={control}
                      render={({ field }) => (
                        <MaintenanceSelect
                          field={field}
                          error={errors.viewinmaintenance}
                        />
                      )}
                      rules={{
                        validate: (v) =>
                          typeof v == "boolean" || "هذا الحقل مطلوب!",
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="uk-modal-footer uk-text-left">
              <button
                className={`uk-button uk-button-secondary uk-margin-small-left uk-border-rounded${
                  isValid ? " uk-modal-close" : ""
                }`}
                type="button"
                disabled={!isDirty}
                onClick={handleSubmit(handleEditBrand)}
              >
                تعديل
              </button>
              <button
                className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
                type="button"
                onClick={resetEditBrandModalValues}
              >
                إلغاء
              </button>
            </div>
          </div>
        </div>
      </div>
      <CustomReactTable
        data={allBrands}
        columns={columns}
        isLoading={isLoading}
      />
    </>
  );
}

export default BrandsGrid;
