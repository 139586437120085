import React, { useEffect } from "react";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAllMaintenanceOrders } from "../../../redux/Maintenance/CustomerOrders/customerOrdersSlice";

function CustomerOrdersPanelSearch() {
  const dispatch = useDispatch();
  const { searchCriteria } = useSelector((state) => state.customerMtcOrders);

  const {
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: searchCriteria,
  });

  useEffect(() => {
    reset(searchCriteria);
  }, [searchCriteria]);

  const searchMaintenanceOrder = (data) => {
    dispatch(getAllMaintenanceOrders(data));
  };

  return (
    <form
      className="uk-grid-small"
      uk-grid=""
      onSubmit={handleSubmit(searchMaintenanceOrder)}
    >
      <div className="uk-width-2-3">
        <label className="uk-form-label uk-text-gray uk-text-bold">
          الجوال
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <CustomInput
            control={control}
            name="mobile"
            error={errors.mobile}
            required={false}
            type="number"
          />
        </div>
      </div>
      <div className="uk-width-1-3">
        <div className="uk-position-relative uk-width-1-1 uk-height-1-1">
          <button
            className="uk-button uk-button-secondary uk-border-rounded uk-position-bottom"
            disabled={!isDirty}
          >
            بحث
          </button>
        </div>
      </div>
    </form>
  );
}

export default CustomerOrdersPanelSearch;
