import React, { useEffect } from "react";
import CategoriesActions from "./CategoriesActions";
import CategoriesGrid from "./CategoriesGrid";
import { useLocation, useNavigate } from "react-router-dom";

function Categories() {
  const { state } = useLocation();
  const carId = state?.carId;
  const carName = state?.carName;
  const navigate = useNavigate();

  useEffect(() => {
    !carId && navigate("/Cars");
  }, [carId]);

  return (
    <div>
      <CategoriesActions carName={carName} carId={carId} />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <CategoriesGrid carId={carId} />
      </div>
    </div>
  );
}

export default Categories;
