import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./User/userSlice";
import brandsSlice from "./CarsManagement/Brands/brandsSlice";
import brandTypesSlice from "./CarsManagement/BrandTypes/brandTypesSlice";
import carsSlice from "./CarsManagement/Cars/carsSlice";
import categoriesSlice from "./CarsManagement/Categories/categoriesSlice";
import carImagesSlice from "./CarsManagement/CarImages/carImagesSlice";
import offersSlice from "./CarsManagement/Offers/offersSlice";
import advicesSlice from "./SystemManagement/Advices/advicesSlice";
import branchesSlice from "./SystemManagement/Branches/branchesSlice";
import maintenanceTypesSlice from "./Maintenance/MaintenanceTypes/maintenanceTypesSlice";
import maintenanceOffersSlice from "./Maintenance/MaintenanceOffers/maintenanceOffersSlice";
import warnSignsSlice from "./SystemManagement/WarnSigns/warnSignsSlice";
import customerOrdersSlice from "./Maintenance/CustomerOrders/customerOrdersSlice";
import sathaOrdersSlice from "./Maintenance/SathaOrders/sathaOrdersSlice";
import newsSlice from "./SystemManagement/News/newsSlice";
import contactMessagesSlice from "./SystemManagement/ContactMessages/contactMessagesSlice";
import banksSlice from "./Banks/banksSlice";
import financeOrdersSlice from "./CarsManagement/FinanceOrders/financeOrdersSlice";
import rolesSlice from "./UsersManagement/Roles/rolesSlice";
import usersSlice from "./UsersManagement/Users/usersSlice";

const rootReducer = combineReducers({
  user: userSlice,
  brands: brandsSlice,
  brandTypes: brandTypesSlice,
  cars: carsSlice,
  categories: categoriesSlice,
  carImages: carImagesSlice,
  offers: offersSlice,
  advices: advicesSlice,
  branches: branchesSlice,
  maintenanceTypes: maintenanceTypesSlice,
  maintenanceOffers: maintenanceOffersSlice,
  warnSigns: warnSignsSlice,
  customerMtcOrders: customerOrdersSlice,
  sathaOrders: sathaOrdersSlice,
  news: newsSlice,
  contactMessages: contactMessagesSlice,
  banks: banksSlice,
  financeOrders: financeOrdersSlice,
  roles: rolesSlice,
  users: usersSlice,
});

export default rootReducer;
