import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCarImage } from "../../../redux/CarsManagement/CarImages/carImagesSlice";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { Controller, useForm } from "react-hook-form";
import StatusSelect from "../../../components/CustomSelect/StatusSelect";

function AddImageForm({ carId }) {
  const dispatch = useDispatch();
  const { addImageFormValues } = useSelector((state) => state.carImages);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: addImageFormValues,
  });

  useEffect(() => {
    reset(addImageFormValues);
  }, [addImageFormValues]);

  // Preview selected image
  const [image, setImage] = useState();
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleAddImage = (data) => {
    const formData = new FormData();

    // append Car ID
    formData.append("car_id", carId);

    for (let key in data) {
      key === "file_name"
        ? formData.append(key, data[key][0])
        : formData.append(key, data[key]);
    }

    dispatch(addCarImage(formData));
  };

  return (
    <form className="uk-grid-small" uk-grid="">
      <div className="uk-width-3-4 uk-grid-small uk-child-width-1-3" uk-grid="">
        <div>
          <label className="uk-form-label uk-text-gray uk-text-bold">
            صورة السيارة
          </label>
          <div className="uk-form-controls uk-margin-small-top">
            <div uk-form-custom="" className="uk-width-1-1">
              <input
                type="file"
                aria-label="Custom controls"
                name="file_name"
                {...register("file_name", {
                  required: "برجاء اختيار صورة!",
                  onChange: handleImageChange,
                  validate: {
                    lessThan2MB: (files) =>
                      files[0]?.size < 2000000 ||
                      "لا يجب أن يتعدى حجم الصورة 2 ميجا بايت",
                    acceptedFormats: (files) =>
                      ["image/jpg", "image/png", "image/jpeg"].includes(
                        files[0]?.type
                      ) || "يجب أن تكون صيغة الصورة JPG, PNG, JPEG",
                  },
                })}
                aria-invalid={errors.file_name ? "true" : "false"}
              />
              <button
                className="uk-button uk-button-default uk-border-rounded uk-width-1-1"
                type="button"
                tabIndex="-1"
              >
                إختر صورة
              </button>
            </div>
            {errors.file_name && (
              <small className="uk-text-danger uk-display-block uk-margin-small-top">
                <span className="uk-margin-small-left" uk-icon="warning"></span>
                {errors.file_name.message}
              </small>
            )}
            {image && addImageFormValues && (
              <small className="uk-text-success uk-display-block uk-margin-small-top">
                <span className="uk-margin-small-left" uk-icon="image"></span>
                {image.name}
              </small>
            )}
          </div>
        </div>
        <div>
          <label className="uk-form-label uk-text-gray uk-text-bold">
            الترتيب
          </label>
          <div className="uk-form-controls uk-margin-small-top">
            <CustomInput
              control={control}
              name="order"
              error={errors.order}
              type="number"
            />
          </div>
        </div>
        <div>
          <label className="uk-form-label uk-text-gray uk-text-bold">
            الحالة
          </label>
          <div className="uk-form-controls uk-margin-small-top">
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <StatusSelect field={field} error={errors.status} />
              )}
              rules={{
                validate: (v) => typeof v == "boolean" || "هذا الحقل مطلوب!",
              }}
            />
          </div>
        </div>
      </div>
      <div className="uk-width-1-4">
        <br />
        <div className="uk-position-relative uk-width-1-1 uk-margin-small-top">
          <button
            className="uk-button uk-button-secondary uk-margin-small-left uk-border-rounded uk-width-1-1"
            type="button"
            onClick={handleSubmit(handleAddImage)}
            disabled={!isValid}
          >
            إضافه
          </button>
        </div>
      </div>
    </form>
  );
}

export default AddImageForm;
