import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NewsEffects from "./newsEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  allNews: [],
  addNewsFormValues: {
    nameAr: "",
    nameEn: "",
    descriptionAr: "",
    descriptionEn: "",
    image: "",
    order: "",
    status: "",
  },
  editNewsFormValues: null,
  addOrEditResult: null,
  isLoading: false,
};
// Get All News
export const getAllNews = createAsyncThunk(
  "news/getAllNews",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NewsEffects.getAllNews();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add News
export const addNews = createAsyncThunk(
  "news/addNews",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await NewsEffects.addNews(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit News
export const editNews = createAsyncThunk(
  "news/editNews",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await NewsEffects.editNews(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    patchEditNewsFormValues: (state, action) => {
      state.editNewsFormValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get All News
    builder.addCase(getAllNews.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllNews.fulfilled, (state, action) => {
      state.allNews = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllNews.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add News
    builder.addCase(addNews.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addNews.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addNews.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit News
    builder.addCase(editNews.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editNews.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editNews.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditNewsFormValues } = newsSlice.actions;
export default newsSlice.reducer;
