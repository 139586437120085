import React from "react";
import OffersActions from "./OffersActions";
import OffersGrid from "./OffersGrid";

function Offers() {
  return (
    <div>
      <OffersActions />
      <div className="uk-padding-small uk-background-default uk-border-rounded">
        <OffersGrid />
      </div>
    </div>
  );
}

export default Offers;
