import React from "react";
import Select from "react-select";
import "./style.css";

function CustomSelect({ field, options, error, placeholder, onChange }) {
  return (
    <>
      <Select
        options={options}
        placeholder={placeholder ?? "اختر"}
        value={options.find((x) => x.value === field.value) ?? field.value}
        onChange={(option) => {
          field.onChange(option ? option.value : option);
          onChange && onChange(option ? option.value : option);
        }}
        aria-invalid={error ? "true" : "false"}
      />
      {error && (
        <small className="uk-text-danger uk-display-block uk-margin-small-top">
          <span className="uk-margin-small-left" uk-icon="warning"></span>
          {error.message}
        </small>
      )}
    </>
  );
}

export default CustomSelect;
