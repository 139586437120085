import React from "react";
import { NavLink } from "react-router-dom";

function NewsActions() {
  return (
    <nav className="uk-position-relative" aria-label="Breadcrumb">
      <ul className="uk-breadcrumb">
        <li>
          <span>إدارة نظام التحكم</span>
        </li>
        <li>
          <span aria-current="page">الأخبار</span>
        </li>
      </ul>
      <NavLink
        to="/News/AddNews"
        className="uk-button uk-button-primary uk-button-small uk-border-rounded uk-position-left"
      >
        <span className="uk-margin-small-left" uk-icon="plus"></span>
        إنشاء
      </NavLink>
    </nav>
  );
}

export default NewsActions;
