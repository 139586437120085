import React, { useEffect, useMemo, useState } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllFinanceOrders } from "../../../redux/CarsManagement/FinanceOrders/financeOrdersSlice";

function FinanceOrdersGrid() {
  const dispatch = useDispatch();
  const { allFinanceOrders, isLoading } = useSelector(
    (state) => state.financeOrders
  );

  const [modalData, setModalData] = useState();
  const handleOrderModalValues = (data) => {
    setModalData(data);
  };

  // Get All Car Finance Orders & Auto refresh Car Finance Orders list
  useEffect(() => {
    dispatch(getAllFinanceOrders());
    setInterval(() => dispatch(getAllFinanceOrders()), 120000);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "السيارة",
        accessor: "carname",
      },
      {
        Header: "الإسم",
        accessor: "customer_name",
      },
      {
        Header: "الجوال",
        accessor: "mobile",
      },
      {
        Header: "الممول",
        accessor: "bankName",
      },
      {
        Header: "فترة التقسيط",
        accessor: "payment_period",
      },
      {
        Header: "المقدم المدفوع",
        accessor: "paid_money",
      },
      {
        Header: "القسط الذي يتوقعه العميل",
        accessor: "month_payment",
      },
      {
        Header: "الحالة",
        accessor: "status",
        Cell: ({ row }) =>
          row.original.status ? (
            <span className="uk-text-success" uk-icon="check"></span>
          ) : (
            <span className="uk-text-danger" uk-icon="close"></span>
          ),
      },
      {
        Header: "ملاحظات",
        accessor: "remarks",
      },
      {
        Header: "الإجراء",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="actions uk-flex uk-flex-center uk-flex-middle">
            <button
              uk-tooltip="عرض التفاصيل"
              className="uk-icon-button"
              uk-icon="eye"
              uk-toggle="target: #FinanceOrderDetailsModal"
              onClick={() => handleOrderModalValues(row.original)}
            ></button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <CustomReactTable
        data={allFinanceOrders}
        columns={columns}
        isLoading={isLoading}
      />
      {/* Finance Order Details */}
      <div
        id="FinanceOrderDetailsModal"
        className="uk-modal-container"
        uk-modal="bg-close: false"
      >
        <div className="uk-modal-dialog">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close=""
          ></button>
          <div className="uk-modal-header uk-text-center">
            <h2 className="uk-modal-title">تفاصيل الطلب</h2>
          </div>
          <div className="uk-modal-body">
            <div className="uk-grid-small uk-child-width-1-3" uk-grid="">
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  اسم العميل
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.customer_name}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  الجوال
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.mobile}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  الإيميل
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.email}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  البنك
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.bankName}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  المُصنع
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.brand_nameAr}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  النوع
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.brand_types_nameAr}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  السيارة
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.carname}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  سنة الصنع
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.manufacture_year}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  السعر
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.price}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  فترة التقسيط
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.payment_period} شهر
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  القسط الذي يتوقعه العميل
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.month_payment} ريال سعودي
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  القسط الشهري المتوقع (تقريباً)
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.outmonth_payment} ريال سعودي
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  المقدم المدفوع
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.paid_money} ريال سعودي
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  الراتب
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.salary} ريال سعودي
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  الإلتزامات
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.commitments} ريال سعودي
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  الدفعة الأخيرة
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.lastPayment}
                </div>
              </div>
              <div>
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  الحالة
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.status ? (
                    <span className="uk-text-success" uk-icon="check"></span>
                  ) : (
                    <span className="uk-text-danger" uk-icon="close"></span>
                  )}
                </div>
              </div>
              <div className="uk-width-1-1">
                <label className="uk-form-label uk-text-gray uk-text-bold">
                  ملاحظات
                </label>
                <div className="uk-form-controls uk-margin-small-top">
                  {modalData?.remarks}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinanceOrdersGrid;
