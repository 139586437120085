import React from "react";
import { Controller } from "react-hook-form";

function CustomInput({
  name,
  control,
  type,
  error,
  placeholder,
  onChange,
  className,
  textarea,
  required = true,
}) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) =>
          textarea ? (
            <textarea
              placeholder={placeholder ?? ""}
              className={className ?? "uk-textarea uk-border-rounded"}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
                onChange && onChange(e.target.value);
              }}
              aria-invalid={error ? "true" : "false"}
            ></textarea>
          ) : (
            <input
              type={type ?? "text"}
              placeholder={placeholder ?? ""}
              className={className ?? "uk-input uk-border-rounded"}
              value={field.value}
              onChange={(e) => {
                field.onChange(
                  type === "number" && e.target.value != ""
                    ? parseFloat(e.target.value)
                    : e.target.value
                );
                onChange &&
                  onChange(
                    type === "number"
                      ? parseInt(e.target.value)
                      : e.target.value
                  );
              }}
              aria-invalid={error ? "true" : "false"}
            />
          )
        }
        rules={{
          required: required && "هذا الحقل مطلوب!",
          pattern: type === "number" && {
            value: /^[0-9]{11}$/,
            message: "صيغة الرقم غير صحيحة!",
          },
          minLength: type === "password" &&
            required && {
              value: 6,
              message: "كلمة المرور لا يجب أن تقل عن 6 أحرف!",
            },
        }}
      />
      {error && (
        <small className="uk-text-danger uk-display-block uk-margin-small-top">
          <span className="uk-margin-small-left" uk-icon="warning"></span>
          {error.message}
        </small>
      )}
    </>
  );
}

export default CustomInput;
