import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAllBrands } from "../../../redux/CarsManagement/Brands/brandsSlice";
import { editCar } from "../../../redux/CarsManagement/Cars/carsSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { colorOptions, manufacturingYears } from "../../../helpers/Utilities";
import { getAllBrandTypes } from "../../../redux/CarsManagement/BrandTypes/brandTypesSlice";
import LoadingOverlay from "../../../helpers/LoadingOverlay/LoadingOverlay";
import StatusSelect from "../../../components/CustomSelect/StatusSelect";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { base_url } from "../../../helpers/Constants";

function EditCar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editCarFormValues, isLoading } = useSelector((state) => state.cars);
  const { allBrands, isLoading: brandsLoading } = useSelector(
    (state) => state.brands
  );
  const { allBrandTypes, isLoading: brandTypesLoading } = useSelector(
    (state) => state.brandTypes
  );

  // Car Selected Luxuries
  const carColors = editCarFormValues?.colors ?? [];

  // Check if there are values or not
  useEffect(() => {
    !editCarFormValues && navigate("/Cars");
  }, [editCarFormValues]);

  // Options For Select
  const manufacturingOptions = manufacturingYears();
  const brandOptions = allBrands.map((brand) => ({
    value: brand.id,
    label: brand.nameAr,
  }));
  const brandTypesOptions = allBrandTypes.map((brandType) => ({
    value: brandType.id,
    label: brandType.nameAr,
  }));

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: editCarFormValues,
  });

  useEffect(() => {
    reset(editCarFormValues);
  }, [editCarFormValues, allBrands, allBrandTypes]);

  // Preview selected image
  const [image, setImage] = useState();
  const handleImageChange = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  // Get All Brands
  useEffect(() => {
    dispatch(getAllBrands());
  }, []);

  // Get Brand Types
  const handleBrandTypes = (brandId) => {
    brandId && dispatch(getAllBrandTypes(brandId));
  };

  useEffect(() => {
    editCarFormValues && dispatch(getAllBrandTypes(editCarFormValues.brand_id));
  }, [editCarFormValues]);

  const handleEditCar = (data) => {
    const formData = new FormData();

    // Append Colors
    const colors = data.colors;
    for (let key in colors) {
      formData.append(`colors[${key}].code`, colors[key]);
    }

    for (let key in data) {
      if (key !== "brandType" && key !== "colors") {
        key === "imageProfile"
          ? formData.append(key, data[key][0])
          : formData.append(key, data[key]);
      }
    }

    dispatch(editCar({ formData, id: data.id })).then(
      (res) => !res.error && navigate("/Cars")
    );
  };

  return (
    <>
      <nav className="uk-position-relative" aria-label="Breadcrumb">
        <ul className="uk-breadcrumb">
          <li>
            <NavLink to="/Cars">السيارات</NavLink>
          </li>
          <li>
            <span aria-current="page">تعديل سيارة</span>
          </li>
        </ul>
      </nav>

      {brandsLoading || brandTypesLoading ? (
        <LoadingOverlay />
      ) : (
        <div className="uk-padding-small uk-background-default uk-border-rounded">
          <form className="uk-grid-small uk-child-width-1-2" uk-grid="">
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                الإسم بالعربي
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="nameAr"
                  error={errors.nameAr}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                الإسم بالإنجليزي
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="nameEn"
                  error={errors.nameEn}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                الوصف بالعربي
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="descriptionAr"
                  error={errors.descriptionAr}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                الوصف بالإنجليزي
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="descriptionEn"
                  error={errors.descriptionEn}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                نظرة عامة بالعربي
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="overviewAr"
                  error={errors.overviewAr}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                نظرة عامة بالإنجليزي
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="overviewEn"
                  error={errors.overviewEn}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                المُصنع
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <Controller
                  name="brand_id"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      placeholder={"اختر مُصنع"}
                      field={field}
                      options={brandOptions}
                      error={errors.brand_id}
                      onChange={(val) => handleBrandTypes(val)}
                    />
                  )}
                  rules={{
                    required: "هذا الحقل مطلوب!",
                  }}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                النوع
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <Controller
                  name="brandType_id"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      placeholder={"اختر نوع"}
                      field={field}
                      options={brandTypesOptions}
                      error={errors.brandType_id}
                    />
                  )}
                  rules={{
                    required: "هذا الحقل مطلوب!",
                  }}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                الترتيب
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="order"
                  error={errors.order}
                  type="number"
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                الحالة
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <StatusSelect field={field} error={errors.status} />
                  )}
                  rules={{
                    validate: (v) =>
                      typeof v == "boolean" || "هذا الحقل مطلوب!",
                  }}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                سنة الصنع
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <Controller
                  name="manufacture_year"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      placeholder={"اختر السنة"}
                      field={field}
                      options={manufacturingOptions}
                      error={errors.manufacture_year}
                    />
                  )}
                  rules={{
                    required: "هذا الحقل مطلوب!",
                  }}
                />
              </div>
            </div>
            <div>
              <label className="uk-form-label uk-text-gray uk-text-bold">
                الصورة
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <div uk-form-custom="">
                  <input
                    type="file"
                    aria-label="Custom controls"
                    name="imageProfile"
                    {...register("imageProfile", {
                      onChange: handleImageChange,
                      validate: image && {
                        lessThan2MB: (files) =>
                          files[0]?.size < 2000000 ||
                          "لا يجب أن يتعدى حجم الصورة 2 ميجا بايت",
                        acceptedFormats: (files) =>
                          ["image/jpg", "image/png", "image/jpeg"].includes(
                            files[0]?.type
                          ) || "يجب أن تكون صيغة الصورة JPG, PNG, JPEG",
                      },
                    })}
                    aria-invalid={errors.imageProfile ? "true" : "false"}
                  />
                  <button
                    className="uk-button uk-button-default uk-border-rounded"
                    type="button"
                    tabIndex="-1"
                  >
                    إختر صورة
                  </button>
                  <img
                    src={
                      image ?? `${base_url}/${editCarFormValues?.imageProfile}`
                    }
                    alt="Selected image"
                    className="uk-height-max-small uk-border-rounded uk-margin-small-right uk-align-left"
                  />
                </div>
                {errors.imageProfile && (
                  <small className="uk-text-danger uk-display-block uk-margin-small-top">
                    <span
                      className="uk-margin-small-left"
                      uk-icon="warning"
                    ></span>
                    {errors.imageProfile.message}
                  </small>
                )}
              </div>
            </div>

            <h4 className="uk-heading-line uk-margin-medium-top uk-margin-small-bottom uk-width-1-1 uk-text-bold">
              <span>الألوان</span>
            </h4>
            {/* All Colors */}
            {colorOptions.map((color) => (
              <div key={color.id} className="uk-form-controls">
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  value={color.value}
                  {...register(`colors`)}
                  defaultChecked={carColors.some(
                    (item) => item.code == color.value
                  )}
                />
                <span
                  className="uk-badge uk-margin-small-left uk-box-shadow-small"
                  style={{
                    backgroundColor: color.value,
                  }}
                ></span>
                {color.label}
              </div>
            ))}
          </form>
          <div className="uk-margin-top uk-text-left">
            <button
              className="uk-button uk-button-secondary uk-margin-small-left uk-border-rounded"
              type="button"
              disabled={!isDirty || isLoading}
              onClick={handleSubmit(handleEditCar)}
            >
              {isLoading ? <div uk-spinner="ratio: 0.5"></div> : "تعديل"}
            </button>
            <NavLink
              to="/Cars"
              className="uk-button uk-button-primary uk-modal-close uk-border-rounded"
              type="button"
            >
              إلغاء
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
}

export default EditCar;
