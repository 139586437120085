import React, { useEffect, useMemo, useState } from "react";
import CustomReactTable from "../../../components/ReactTable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllContactMessages } from "../../../redux/SystemManagement/ContactMessages/contactMessagesSlice";

function ContactMessagesGrid() {
  const dispatch = useDispatch();
  const { allContactMessages, isLoading } = useSelector(
    (state) => state.contactMessages
  );

  // Get All Contact Messages
  useEffect(() => {
    dispatch(getAllContactMessages());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "اسم العميل",
        accessor: "name",
      },
      {
        Header: "رقم الجوال",
        accessor: "phone",
      },
      {
        Header: "الإيميل",
        accessor: "email",
      },
      {
        Header: "العنوان",
        accessor: "address",
      },
      {
        Header: "الرسالة",
        accessor: "message",
      },
    ],
    []
  );

  return (
    <>
      <CustomReactTable
        data={allContactMessages}
        columns={columns}
        isLoading={isLoading}
      />
      ;
    </>
  );
}

export default ContactMessagesGrid;
