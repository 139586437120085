import React from "react";
import { NavLink } from "react-router-dom";

function RolesActions() {
  return (
    <nav className="uk-position-relative" aria-label="Breadcrumb">
      <ul className="uk-breadcrumb">
        <li>
          <NavLink to="/Users">المستخدمين</NavLink>
        </li>
        <li>
          <span aria-current="page">الأدوار</span>
        </li>
      </ul>
      <NavLink
        to="/Roles/AddRole"
        className="uk-button uk-button-primary uk-button-small uk-border-rounded uk-position-left"
      >
        <span className="uk-margin-small-left" uk-icon="plus"></span>
        إنشاء
      </NavLink>
    </nav>
  );
}

export default RolesActions;
