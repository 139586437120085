import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import OffersEffects from "./offersEffects";
import { showNotification } from "../../../helpers/ShowNotification";

const initialState = {
  searchCriteria: {
    offerName: "",
    status: "",
  },
  allOffers: [],
  addOfferFormValues: {
    nameAr: "",
    nameEn: "",
    car_id: "",
    order: "",
    status: "",
    offer_start: "",
    offer_end: "",
    price_before: "",
    price_after: "",
    offer_discount: "",
  },
  editOfferFormValues: null,
  addOrEditResult: {},
  isLoading: false,
};
// Get All Offers
export const getAllOffers = createAsyncThunk(
  "offers/getAllOffers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await OffersEffects.getAllOffers();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Offer
export const addOffer = createAsyncThunk(
  "offers/addOffer",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await OffersEffects.addOffer(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Offer
export const editOffer = createAsyncThunk(
  "offers/editOffer",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await OffersEffects.editOffer(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const offersSlice = createSlice({
  name: "Offers",
  initialState,
  reducers: {
    patchEditOfferFormValues: (state, action) => {
      state.editOfferFormValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get All Offers
    builder.addCase(getAllOffers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllOffers.fulfilled, (state, action) => {
      state.allOffers = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllOffers.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Add Offer
    builder.addCase(addOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addOffer.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "add" });
    });
    builder.addCase(addOffer.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });

    // Edit Offer
    builder.addCase(editOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editOffer.fulfilled, (state, action) => {
      state.addOrEditResult = action.payload;
      state.isLoading = false;
      showNotification({ type: "edit" });
    });
    builder.addCase(editOffer.rejected, (state) => {
      state.isLoading = false;
      showNotification({ type: "error" });
    });
  },
});

export const { patchEditOfferFormValues } = offersSlice.actions;
export default offersSlice.reducer;
